import React, { useState, useEffect } from 'react';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Badge,
  Avatar,
  Typography,
  Collapse,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ChatIcon from '@mui/icons-material/Chat';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { realTimeDb, db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';

function Sidebar({ activeItem, setActiveItem, onHoverChange }) {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [chats, setChats] = useState([]);
  const [openChats, setOpenChats] = useState(true);

  const expandedWidth = 250;
  const collapsedWidth = 64;

  const menuItems = [
    {
      title: 'Dashboard',
      icon: <DashboardIcon sx={{ color: 'white' }} />,
      path: '/dashboard'
    },
    {
      title: 'Tickets',
      icon: <ConfirmationNumberIcon sx={{ color: 'white' }} />,
      path: '/tickets'
    },
    {
      title: 'Ticket erstellen',
      icon: <AddCircleIcon sx={{ color: 'white' }} />,
      path: '/create-ticket'
    },
    {
      title: 'Geschlossene Tickets',
      icon: <ArchiveIcon sx={{ color: 'white' }} />,
      path: '/closed-tickets'
    },
    {
      title: 'Chats',
      icon: <ChatIcon sx={{ color: 'white' }} />,
      path: '/chats'
    }
  ];

  const handleMouseEnter = () => {
    setIsHovered(true);
    onHoverChange(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    onHoverChange(false);
  };

  const handleNavigation = (path) => {
    if (!currentUser) {
      return;
    }
    setActiveItem(path);
    if (path.startsWith('chat-')) {
      const chatId = path.replace('chat-', '');
      navigate(`/chats/${chatId}`);
    } else {
      navigate(`/${path}`);
    }
  };

  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
    handleClose();
  };

  return (
    <Box 
      sx={{
        width: isHovered ? expandedWidth : collapsedWidth,
        background: '#1a1f2e !important',
        color: 'white',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        overflowY: 'auto',
        boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        display: 'flex',
        flexDirection: 'column'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box sx={{ 
        p: 3, 
        borderBottom: '1px solid rgba(255,255,255,0.1)',
        background: 'rgba(255,255,255,0.05)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: isHovered ? 'flex-start' : 'center'
      }}>
        {isHovered && (
          <Typography 
            variant="h6" 
            sx={{ 
              color: 'white',
              fontWeight: 600,
              letterSpacing: '0.5px',
              transition: 'opacity 0.3s ease',
              whiteSpace: 'nowrap'
            }}
          >
            Smart Resolve
          </Typography>
        )}
      </Box>

      <List sx={{ pt: 2 }}>
        {menuItems.map((item) => (
          <ListItem
            key={item.title}
            button
            onClick={() => {
              navigate(item.path);
              setActiveItem(item.title.toLowerCase());
            }}
            sx={{
              mb: 1,
              borderRadius: 1,
              backgroundColor: 
                activeItem === item.title.toLowerCase() 
                  ? 'rgba(255, 255, 255, 0.1)' 
                  : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {isHovered && <ListItemText primary={item.title} />}
          </ListItem>
        ))}
      </List>

      <Box sx={{ 
        mt: 'auto', 
        p: 2, 
        borderTop: '1px solid rgba(255,255,255,0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: isHovered ? 'space-between' : 'center'
      }}>
        <IconButton 
          onClick={handleUserClick}
          sx={{ color: 'white' }}
        >
          <AccountCircleIcon />
        </IconButton>
        {isHovered && (
          <Typography variant="body2" sx={{ ml: 1 }}>
            {currentUser?.email}
          </Typography>
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Abmelden</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}

export default Sidebar;
