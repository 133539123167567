import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from '../contexts/AuthContext';
import ConsultantTicketView from './ConsultantTicketView';
import Split from 'react-split';
import './split.css';
import RestoreIcon from '@mui/icons-material/Restore';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import HighPriorityIcon from '@mui/icons-material/PriorityHigh';
import MediumPriorityIcon from '@mui/icons-material/ArrowUpward';
import LowPriorityIcon from '@mui/icons-material/ArrowDownward';
import CircleIcon from '@mui/icons-material/Circle';

function ClosedTickets() {
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const { currentUser, userRole } = useAuth();
  const [consultants, setConsultants] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [splitSizes, setSplitSizes] = useState([67, 33]);

  useEffect(() => {
    setSplitSizes([67, 33]);
  }, []);

  const columns = [
    { 
      field: 'ticketNumber', 
      headerName: 'Ticket-Nr.', 
      width: 110,
      renderCell: (params) => {
        if (!params.value || !params.value.startsWith('T-')) {
          return `T-${params.row.id.substring(0, 7)}`;
        }
        return params.value;
      }
    },
    { 
      field: 'kunde', 
      headerName: 'Kunde', 
      width: 120
    },
    { 
      field: 'ansprechpartner', 
      headerName: 'Ansprechpartner', 
      width: 150
    },
    { 
      field: 'kategorie', 
      headerName: 'Kategorie', 
      width: 120
    },
    { 
      field: 'assignedTo', 
      headerName: 'Zugewiesen an', 
      width: 140,
      valueGetter: (params) => {
        if (!params.row.assignedTo) return 'Nicht zugewiesen';
        return consultants[params.row.assignedTo] || 'Nicht zugewiesen';
      }
    },
    { 
      field: 'status', 
      headerName: 'Status', 
      width: 120,
      renderCell: (params) => {
        return <StatusCell status={params.row.status} />;
      }
    },
    { 
      field: 'priorität', 
      headerName: 'Priorität', 
      width: 120,
      renderCell: (params) => {
        return <PriorityCell priority={params.row.priorität} />;
      }
    },
    { 
      field: 'erstelltAm', 
      headerName: 'Erstellt am', 
      width: 120,
      valueFormatter: (params) => {
        if (!params.value) return '';
        const date = new Date(params.value);
        return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      }
    },
    { 
      field: 'closedAt', 
      headerName: 'Geschlossen am', 
      width: 120,
      valueFormatter: (params) => {
        if (!params.value) return '';
        const date = new Date(params.value);
        return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      }
    },
    {
      field: 'actions',
      headerName: 'Wieder öffnen',
      width: 130,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleOpenDialog(params.row.id)}
          sx={{
            border: '2px solid #2196f3',
            color: 'white',
            backgroundColor: 'transparent',
            '&:hover': {
              border: '2px solid #64b5f6',
              backgroundColor: 'rgba(33, 150, 243, 0.1)',
              color: '#64b5f6'
            },
            width: '80%',
            textTransform: 'none'
          }}
        >
          Öffnen
        </Button>
      )
    }
  ];

  const fetchClosedTickets = useCallback(async () => {
    try {
      const ticketsRef = collection(db, 'Tickets');
      let q;

      if (userRole === 'user') {
        q = query(
          ticketsRef, 
          where('status', '==', 'geschlossen'),
          where('createdBy', '==', currentUser.uid)
        );
      } else {
        q = query(ticketsRef, where('status', '==', 'geschlossen'));
      }

      const querySnapshot = await getDocs(q);
      const ticketsPromises = querySnapshot.docs.map(async (docSnapshot) => {
        const ticket = docSnapshot.data();
        const ticketId = docSnapshot.id;

        return {
          id: ticketId,
          ...ticket,
          title: ticket.title,
          kunde: ticket.companyId,
          ansprechpartner: ticket.createdByName,
          kategorie: ticket.department,
          zugewiesenAn: ticket.assignedTo || 'Nicht zugewiesen',
          status: ticket.status,
          priorität: ticket.priority,
          erstelltAm: ticket.createdAt,
          closedAt: ticket.closedAt,
          description: ticket.description,
          internalPriority: ticket.internalPriority,
          internalNotes: ticket.internalNotes,
          createdByEmail: ticket.createdByEmail,
          attachments: ticket.attachments
        };
      });

      const processedTickets = await Promise.all(ticketsPromises);
      setTickets(processedTickets);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Laden der geschlossenen Tickets:', error);
      setLoading(false);
    }
  }, [currentUser, userRole]);

  useEffect(() => {
    fetchClosedTickets();
  }, [currentUser, userRole]);

  useEffect(() => {
    const fetchConsultants = async () => {
      try {
        const consultantsRef = collection(db, 'consultants');
        const consultantsSnapshot = await getDocs(consultantsRef);
        const consultantData = {};
        
        consultantsSnapshot.forEach(doc => {
          const data = doc.data();
          consultantData[doc.id] = data.firstName && data.surName 
            ? `${data.firstName} ${data.surName}`
            : data.firstName || data.surName || 'Unbekannt';
        });
        
        setConsultants(consultantData);
      } catch (error) {
        console.error('Fehler beim Laden der Consultants:', error);
      }
    };

    fetchConsultants();
  }, []);

  const handleRowClick = (params) => {
    setSelectedTicket(params.row);
  };

  const handleOpenDialog = (ticketId) => {
    setSelectedTicketId(ticketId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTicketId(null);
  };

  const handleConfirmReopen = async () => {
    if (selectedTicketId) {
      try {
        const ticketRef = doc(db, 'Tickets', selectedTicketId);
        const timestamp = new Date().toISOString();

        // Neuer Historien-Eintrag für das Wieder-Öffnen
        const historyEntry = {
          action: 'reopened',
          timestamp: timestamp,
          assignedBy: currentUser.uid,
          status: 'offen'
        };

        await updateDoc(ticketRef, {
          status: 'offen',
          reopenedAt: timestamp,
          reopenedBy: currentUser.uid,
          assignmentHistory: arrayUnion(historyEntry)
        });
        
        fetchClosedTickets();
        handleCloseDialog();
      } catch (error) {
        console.error('Fehler beim Wieder-Öffnen des Tickets:', error);
      }
    }
  };

  return (
    <div style={{ height: 'calc(100vh - 64px)', width: '100%', overflow: 'hidden' }}>
      <Split
        style={{ display: 'flex', height: '100%' }}
        sizes={splitSizes}
        minSize={[400, 300]}
        gutterSize={10}
        className="split"
        onDragEnd={(sizes) => {
          setSplitSizes(sizes);
        }}
      >
        <div style={{ overflow: 'hidden', height: '100%' }}>
          <DataGrid
            rows={tickets}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25]}
            onRowClick={handleRowClick}
            loading={loading}
            getRowClassName={(params) =>
              params.row.id === selectedTicket?.id ? 'selected-row' : ''
            }
            hideFooterSelectedRowCount={true}
            sx={{
              height: '100%',
              '& .MuiDataGrid-root': {
                height: '100%',
                backgroundColor: 'transparent !important'
              },
              '& .MuiDataGrid-main, & .MuiDataGrid-virtualScroller, & .MuiDataGrid-virtualScrollerContent, & .MuiDataGrid-virtualScrollerRenderZone': {
                overflowY: 'hidden !important',
                overflowX: 'hidden !important',
                '&::-webkit-scrollbar': { display: 'none !important' },
                scrollbarWidth: 'none !important',
                '-ms-overflow-style': 'none !important'
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                backgroundColor: 'transparent !important',
                '&:hover': {
                  backgroundColor: 'rgba(30, 41, 59, 0.4) !important'
                },
                maxHeight: '42px !important',
                minHeight: '42px !important'
              },
              '& .selected-row': {
                backgroundColor: 'rgba(0, 127, 255, 0.15) !important',
                '&:hover': {
                  backgroundColor: 'rgba(30, 41, 59, 0.4) !important'
                }
              },
              '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
                border: 'none !important',
                boxShadow: 'none !important'
              },
              '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none !important'
              },
              '& .MuiDataGrid-cell': {
                color: 'white',
                backgroundColor: 'transparent !important',
                fontSize: '0.8rem !important',
                padding: '0 8px !important',
                maxHeight: '42px !important',
                minHeight: '42px !important'
              },
              '& .MuiDataGrid-columnHeaders': {
                color: 'white',
                backgroundColor: 'transparent !important',
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                fontSize: '0.85rem !important',
                fontWeight: 600,
                maxHeight: '42px !important',
                minHeight: '42px !important'
              },
              '& .MuiDataGrid-row, & .MuiDataGrid-cell': {
                maxHeight: '42px !important',
                minHeight: '42px !important'
              },
              '& .MuiDataGrid-columnHeader': {
                maxHeight: '48px !important',
                minHeight: '48px !important'
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: 'transparent !important',
                borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                minHeight: '36px !important',
                maxHeight: '36px !important',
                fontSize: '0.8rem !important'
              },
              '& .MuiTablePagination-root': {
                color: 'rgba(255, 255, 255, 0.7)',
                fontSize: '0.75rem !important'
              },
              '& .MuiTablePagination-actions': {
                color: 'rgba(255, 255, 255, 0.7)'
              },
              backgroundColor: 'transparent !important',
              backdropFilter: 'blur(8px)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px'
            }}
          />
        </div>
        <div style={{ overflow: 'auto', height: '100%' }}>
          {selectedTicket && (
            <ConsultantTicketView
              ticket={selectedTicket}
              onTicketUpdate={fetchClosedTickets}
            />
          )}
        </div>
      </Split>
      
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: '#1a1a1a',
            color: 'white'
          }
        }}
      >
        <DialogTitle>Ticket wieder öffnen</DialogTitle>
        <DialogContent>
          Möchten Sie dieses Ticket wirklich wieder öffnen?
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog}
            sx={{ color: 'white' }}
          >
            Abbrechen
          </Button>
          <Button 
            onClick={handleConfirmReopen}
            variant="contained" 
            color="primary"
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const StatusCell = ({ status }) => {
  let icon;
  let color;
  let label;
  
  switch(status?.toLowerCase()) {
    case 'in bearbeitung':
      icon = <PendingIcon sx={{ fontSize: 16 }} />;
      color = '#3b82f6'; // blau
      label = 'In Bearbeitung';
      break;
    case 'geschlossen':
      icon = <CheckCircleIcon sx={{ fontSize: 16 }} />;
      color = '#10b981'; // grün
      label = 'Geschlossen';
      break;
    case 'offen':
      icon = <ErrorIcon sx={{ fontSize: 16 }} />;
      color = '#f59e0b'; // orange
      label = 'Offen';
      break;
    default:
      icon = <CircleIcon sx={{ fontSize: 16 }} />;
      color = '#9ca3af'; // grau
      label = status || 'Unbekannt';
  }
  
  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      gap: 1
    }}>
      {icon}
      <Typography sx={{ 
        color, 
        fontSize: '0.85rem'
      }}>
        {label}
      </Typography>
    </Box>
  );
};

const PriorityCell = ({ priority }) => {
  let icon;
  let color;
  let label;

  switch(priority?.toLowerCase()) {
    case 'hoch':
    case 'high':
      icon = <HighPriorityIcon sx={{ fontSize: 16 }} />;
      color = '#ef4444'; // rot
      label = 'Hoch';
      break;
    case 'mittel':
    case 'medium':
      icon = <MediumPriorityIcon sx={{ fontSize: 16 }} />;
      color = '#f59e0b'; // orange
      label = 'Mittel';
      break;
    case 'niedrig':
    case 'low':
      icon = <LowPriorityIcon sx={{ fontSize: 16 }} />;
      color = '#10b981'; // grün
      label = 'Niedrig';
      break;
    default:
      icon = <CircleIcon sx={{ fontSize: 16 }} />;
      color = '#9ca3af'; // grau
      label = priority || 'Unbekannt';
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      gap: 1
    }}>
      {icon}
      <Typography sx={{ color, fontSize: '0.85rem' }}>
        {label}
      </Typography>
    </Box>
  );
};

export default ClosedTickets; 