import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import TicketManager from './components/TicketManager';
import CreateTicket from './components/CreateTicket';
import Chats from './components/Chats';
import Dashboard from './components/Dashboard';
import Login from './components/Login/Login';
import Unauthorized from './components/Unauthorized/Unauthorized';
import { ProtectedRoute } from './components/ProtectedRoute';
import Box from '@mui/material/Box';
import { TicketProvider } from './components/TicketContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import './App.css';
import ChatOverview from './components/ChatOverview';
import { NotificationProvider } from './contexts/NotificationContext';
import ClosedTickets from './components/ClosedTickets';

// Separate Komponente für den authentifizierten Inhalt
function AuthenticatedContent() {
  const [activeItem, setActiveItem] = useState('dashboard');
  const [isSidebarHovered, setIsSidebarHovered] = useState(false);

  const SIDEBAR_EXPANDED = 250;
  const SIDEBAR_COLLAPSED = 64;

  return (
    <Box className="app-container">
      <Sidebar 
        activeItem={activeItem} 
        setActiveItem={setActiveItem}
        onHoverChange={setIsSidebarHovered}
      />
      <Box 
        className="main-content"
        sx={{
          marginLeft: isSidebarHovered ? `${SIDEBAR_EXPANDED}px` : `${SIDEBAR_COLLAPSED}px`,
          transition: 'margin-left 0.3s ease',
          width: `calc(100% - ${isSidebarHovered ? SIDEBAR_EXPANDED : SIDEBAR_COLLAPSED}px)`,
          position: 'relative',
          background: 'transparent',
          backgroundColor: 'transparent !important',
          padding: '20px',
          boxSizing: 'border-box',
          overflow: 'hidden'
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/tickets" element={<TicketManager />} />
          <Route path="/create-ticket" element={<CreateTicket />} />
          <Route path="/chats" element={<ChatOverview />} />
          <Route path="/chats/:chatId" element={<ChatOverview />} />
          <Route path="/chats/:ticketId" element={<Chats />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route 
            path="/closed-tickets" 
            element={
              
                <ClosedTickets />
              
            } 
          />
        </Routes>
      </Box>
    </Box>
  );
}

// Neue Komponente für die Root-Weiterleitung
function RootRedirect() {
  const { currentUser } = useAuth();
  
  // Wenn Benutzer angemeldet ist, zum Dashboard, sonst zum Login
  return currentUser ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />;
}

// Neue Komponente für die Login-Route
function LoginRoute() {
  const { currentUser } = useAuth();
  
  // Wenn bereits angemeldet, direkt zum Dashboard
  return currentUser ? <Navigate to="/dashboard" replace /> : <Login />;
}

// Hauptkomponente
export default function App() {
  return (
    <Router>
      <AuthProvider>
        <NotificationProvider>
          <TicketProvider>
            <Routes>
              {/* Root-Route mit intelligenter Weiterleitung */}
              <Route path="/" element={<RootRedirect />} />
              
              {/* Login-Route mit Check ob bereits angemeldet */}
              <Route path="/login" element={<LoginRoute />} />
              
              {/* Geschützte Routen */}
              <Route path="/*" element={
                <ProtectedRoute>
                  <AuthenticatedContent />
                </ProtectedRoute>
              } />
            </Routes>
          </TicketProvider>
        </NotificationProvider>
      </AuthProvider>
    </Router>
  );
}

