import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './App.css';
// Service Worker Import entfernen
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const APP_VERSION = "1.0.4"; // Bei jeder Deployment-Änderung erhöhen

// WICHTIGE ÄNDERUNG: Die Abmeldung mit Flag im LocalStorage
if ('serviceWorker' in navigator) {
  // Prüfen, ob wir bereits einen Abmeldevorgang durchgeführt haben
  const swDeregistered = localStorage.getItem('sw_deregistered');
  
  if (!swDeregistered) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
        registration.unregister();
        console.log('Service Worker abgemeldet');
      }
      // Markierung setzen, dass wir den Service Worker abgemeldet haben
      localStorage.setItem('sw_deregistered', 'true');
    });
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Code zum Abmelden aller existierenden Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => {
      registration.unregister();
      console.log('Service Worker abgemeldet:', registration.scope);
    });
  });
}

// Service Worker Registrierungsaufruf entfernen
// serviceWorkerRegistration.register({...});
