import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase/config';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { signOut } from 'firebase/auth';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Benutzerinformationen aus Firestore laden
          const userDoc = await getDoc(doc(db, 'User', user.uid));
          const userData = userDoc.data();
          
          const userWithDetails = {
            ...user,
            uid: user.uid,
            email: user.email,
            firstName: userData?.firstName || '',
            surName: userData?.surName || '',
            companyId: userData?.companyId,
            role: userData?.role || 'user'
          };
          
          setCurrentUser(userWithDetails);
        } catch (error) {
          console.error('Fehler beim Laden der Benutzerinformationen:', error);
        }
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const fetchUserRole = async (uid) => {
      try {
        // SuperAdmin Collection prüfen
        let userDoc = await getDoc(doc(db, 'SuperAdmin', uid));
        if (userDoc.exists()) {
          setUserRole('Admin');
          return;
        }

        // Corporates Collection durchsuchen
        const corporatesSnapshot = await getDocs(collection(db, 'Corporates'));
        for (const corporate of corporatesSnapshot.docs) {
          // Prüfen ob die UID in corporateAdmins Array existiert
          const corporateData = corporate.data();
          if (corporateData.corporateAdmins && corporateData.corporateAdmins.includes(uid)) {
            setUserRole('CorporateAdmin');
            return;
          }
        }

        // User Collection prüfen
        userDoc = await getDoc(doc(db, 'User', uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.role);
          return;
        }

        // Consultants Collection prüfen
        userDoc = await getDoc(doc(db, 'consultants', uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.role);
          return;
        }
        
        console.error('Benutzer in keiner Collection gefunden');
        setUserRole(null);
        
      } catch (error) {
        console.error('Fehler beim Laden der Benutzerrolle:', error);
        setUserRole(null);
      }
    };

    if (currentUser?.uid) {
      fetchUserRole(currentUser.uid);
    } else {
      setUserRole(null);
    }
  }, [currentUser]);

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  };

  const value = {
    currentUser,
    logout,
    loading,
    userRole
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
