import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || 'your-secure-key';

export class EncryptionService {
  static async encryptFile(file) {
    try {
      const fileReader = new FileReader();
      
      return new Promise((resolve, reject) => {
        fileReader.onload = () => {
          try {
            // Konvertiere Binärdaten zu Base64
            const base64String = btoa(fileReader.result);
            
            // Verschlüssele den Base64-String
            const encrypted = CryptoJS.AES.encrypt(
              base64String,
              ENCRYPTION_KEY
            ).toString();
            
            // Erstelle einen Blob mit dem verschlüsselten Text
            const blob = new Blob([encrypted], { type: 'text/plain' });
            
            resolve({
              blob,
              originalName: file.name,
              originalType: file.type,
              size: file.size
            });
          } catch (err) {
            reject(new Error(`Verschlüsselungsfehler: ${err.message}`));
          }
        };
        
        fileReader.onerror = () => reject(new Error('Fehler beim Lesen der Datei'));
        fileReader.readAsBinaryString(file);
      });
    } catch (error) {
      throw new Error(`Allgemeiner Fehler: ${error.message}`);
    }
  }

  static async decryptFile(encryptedBlob, originalType) {
    try {
      // Lese den verschlüsselten Text aus dem Blob
      const encryptedText = await encryptedBlob.text();
      
      // Entschlüssele den Text
      const decrypted = CryptoJS.AES.decrypt(encryptedText, ENCRYPTION_KEY);
      
      // Konvertiere den entschlüsselten Text von Base64 zurück in Binärdaten
      const binaryStr = atob(decrypted.toString(CryptoJS.enc.Utf8));
      
      // Konvertiere den binären String in ein Uint8Array
      const bytes = new Uint8Array(binaryStr.length);
      for (let i = 0; i < binaryStr.length; i++) {
        bytes[i] = binaryStr.charCodeAt(i);
      }
      
      // Erstelle einen Blob mit den ursprünglichen Binärdaten
      return new Blob([bytes], { type: originalType });
    } catch (error) {
      console.error('Entschlüsselungsfehler:', {
        error,
        blobSize: encryptedBlob.size,
        type: originalType
      });
      throw new Error(`Entschlüsselungsfehler: ${error.message}`);
    }
  }
}
