import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ref, onValue, push, set, get, serverTimestamp } from 'firebase/database';
import { getDoc, doc, collection, getDocs, updateDoc, arrayUnion } from 'firebase/firestore';
import { realTimeDb, db } from '../firebase/config.js';
import { 
  Box, 
  Avatar, 
  Typography, 
  IconButton,
  Button,
  Tooltip,
  Grid,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle.js';
import PersonIcon from '@mui/icons-material/Person.js';
import PersonAddIcon from '@mui/icons-material/PersonAdd.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext.js';
import { useNotifications } from '../contexts/NotificationContext.js';
import './Chats.css';
import notificationSoundFile from '../assets/sound/notification.mp3';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as databaseRef } from 'firebase/database';
import { EncryptionService } from '../services/EncryptionService.js';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageModal from './ImageModal';

const groupMessagesByDate = (messages) => {
  const groups = {};
  messages.forEach(message => {
    const date = new Date(message.timestamp).toLocaleDateString();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
  });
  return groups;
};

const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['image'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
    matchers: [
      ['IMG', (node, delta) => {
        return delta;
      }]
    ]
  }
};

const formats = [
  'header',
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'image'
];

function Chats({ 
  ticketId: propTicketId, 
  hideSelection = false, 
  singleChat = false,
  isInTicketView = false
}) {
  const [selectedChat, setSelectedChat] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isTyping, setIsTyping] = useState({});
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [allTicketChats, setAllTicketChats] = useState([]);
  const [audio] = useState(new Audio(notificationSoundFile));
  const [lastMessageTimestamp, setLastMessageTimestamp] = useState(null);
  const { soundEnabled, desktopNotificationsEnabled } = useNotifications();
  const audioRef = useRef(null);
  const { currentUser, userRole } = useAuth();
  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [userDetails, setUserDetails] = useState({});
  const [ticketTitle, setTicketTitle] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [ticketDetails, setTicketDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const userId = currentUser?.uid;
  const [hasAccess, setHasAccess] = useState(false);
  const [consultants, setConsultants] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedConsultant, setSelectedConsultant] = useState('');
  const [unreadMessages, setUnreadMessages] = useState({});
  const [lastReadTimestamp, setLastReadTimestamp] = useState({});
  const [attachments, setAttachments] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const quillRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const ticketId = propTicketId || location.pathname.split('/').pop();
  const storage = getStorage();

  // Hilfsfunktion zum Formatieren des Datums
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  const addEmoji = (emoji) => {
    setMessage(prev => prev + emoji);
  };

  const handleFileSelect = async (event) => {
    event.preventDefault();
    const files = Array.from(event.target.files);
    console.log('Ausgewählte Dateien:', files);

    if (!files.length) return;

    try {
      const storage = getStorage();
      
      for (const file of files) {
        const fileName = `${Date.now()}-${file.name}`;
        const filePath = `tickets/${ticketId}/${fileName}`;
        
        const fileReference = storageRef(storage, filePath);
        
        console.log('Starte Upload für:', fileName);
        
        const uploadResult = await uploadBytes(fileReference, file);
        console.log('Upload erfolgreich:', uploadResult);
        
        const downloadURL = await getDownloadURL(uploadResult.ref);
        console.log('Download URL:', downloadURL);

        setSelectedFiles(prevFiles => [...prevFiles, {
          name: file.name,
          url: downloadURL,
          type: file.type,
          size: file.size
        }]);
      }
    } catch (error) {
      console.error('Upload error:', error);
      console.error('Error details:', error.message);
      alert('Fehler beim Hochladen der Datei: ' + error.message);
    }
  };

  const removeAttachment = (attachmentId) => {
    setAttachments(prev => prev.filter(att => att.id !== attachmentId));
  };

  const handleSendMessage = async () => {
    if (!message.trim() && selectedFiles.length === 0) return;

    setIsSending(true);
    try {
      let senderName = '';
      
      // Prüfe zuerst, ob es ein Consultant ist
      const consultantDoc = await getDoc(doc(db, 'consultants', currentUser.uid));
      if (consultantDoc.exists()) {
        const consultantData = consultantDoc.data();
        senderName = `${consultantData.firstName} ${consultantData.surName}`;
      } else {
        // Wenn kein Consultant, dann hole User-Daten
        const userDoc = await getDoc(doc(db, 'User', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          senderName = `${userData.firstName} ${userData.surName}`;
        } else {
          // Fallback, falls keine Daten gefunden wurden
          senderName = currentUser.email || 'Unbekannter Benutzer';
        }
      }
      
      const messageData = {
        text: message,
        userId: currentUser.uid,
        userName: currentUser.email,
        customerName: senderName, // Verwende den ermittelten Namen
        timestamp: serverTimestamp(),
        attachments: selectedFiles
      };

      const chatRef = databaseRef(realTimeDb, `chats/${ticketId}`);
      const newMessageRef = push(chatRef);
      await set(newMessageRef, messageData);

      setMessage('');
      setSelectedFiles([]);
      setIsSending(false);
    } catch (error) {
      console.error('Error sending message:', error);
      setIsSending(false);
    }
  };

  const handleTyping = (chatId) => {
    if (!isTyping[chatId]) {
      setIsTyping(prev => ({
        ...prev,
        [chatId]: true
      }));
    }

    if (typingTimeout) clearTimeout(typingTimeout);
    const timeout = setTimeout(() => {
      setIsTyping(prev => ({
        ...prev,
        [chatId]: false
      }));
    }, 2000);
    setTypingTimeout(timeout);
  };

  useEffect(() => {
    if (hideSelection && userId && allTicketChats.length > 0) {
      const userChat = allTicketChats.find(chat => chat.id.toString() === userId.toString());
      if (userChat) {
        setSelectedChat(userChat);
      }
    }
  }, [userId, hideSelection, allTicketChats]);

  useEffect(() => {
    if (!ticketId) return;

    const chatRef = ref(realTimeDb, `chats/${ticketId}`);
    let isSubscribed = true;

    const loadInitialData = async () => {
      try {
        const snapshot = await get(chatRef);
        if (snapshot.exists() && isSubscribed) {
          const chatData = snapshot.val() || {};
          const messageArray = Object.entries(chatData)
            .map(([msgId, msg]) => ({
              id: msgId,
              ...msg,
            }))
            .filter(msg => msg.text)
            .sort((a, b) => a.timestamp - b.timestamp);

          setMessages(messageArray);
          setSelectedChat({
            id: ticketId,
            title: `Ticket #${ticketId}`,
            messages: messageArray,
          });
        }
      } catch (error) {
        console.error('Fehler beim Laden der Chat-Daten:', error);
      }
    };

    loadInitialData();

    const unsubscribe = onValue(chatRef, (snapshot) => {
      if (!isSubscribed) return;

      const chatData = snapshot.val() || {};
      const messageArray = Object.entries(chatData)
        .map(([msgId, msg]) => ({
          id: msgId,
          ...msg,
        }))
        .filter(msg => msg.text)
        .sort((a, b) => a.timestamp - b.timestamp);

      setMessages(messageArray);
      setSelectedChat({
        id: ticketId,
        title: `Ticket #${ticketId}`,
        messages: messageArray,
      });
    }, (error) => {
      console.error('Fehler beim Überwachen der Chat-Daten:', error);
    });

    return () => {
      isSubscribed = false;
      unsubscribe();
    };
  }, [ticketId]);

  useEffect(() => {
    if (!singleChat && !hideSelection) {
      const chatsRef = ref(realTimeDb, 'chats');
      
      const loadChats = async () => {
        const snapshot = await get(chatsRef);
        if (snapshot.exists()) {
          const chatsData = snapshot.val();
          const chatArray = Object.entries(chatsData)
            .map(([ticketId, chatData]) => {
              const messageArray = Object.entries(chatData)
                .map(([msgId, msg]) => ({
                  id: msgId,
                  ...msg
                }))
                .filter(msg => msg.text);
              
              const lastMessage = messageArray.sort((a, b) => b.timestamp - a.timestamp)[0];
              
              return {
                id: ticketId,
                title: `Ticket #${ticketId}`,
                lastMessage: lastMessage?.text || 'Keine Nachrichten',
                timestamp: lastMessage?.timestamp || Date.now(),
                userName: lastMessage?.userName || 'System'
              };
            });
          
          setAllTicketChats(chatArray);
        }
      };

      loadChats();
      
      const unsubscribe = onValue(chatsRef, (snapshot) => {
        if (snapshot.exists()) {
          const chatsData = snapshot.val();
          const chatArray = Object.entries(chatsData).map(([ticketId, chatData]) => {
            const messageArray = Object.entries(chatData)
              .map(([msgId, msg]) => ({
                id: msgId,
                ...msg
              }))
              .filter(msg => msg.text);
            
            const lastMessage = messageArray.sort((a, b) => b.timestamp - a.timestamp)[0];
            
            return {
              id: ticketId,
              title: `Ticket #${ticketId}`,
              lastMessage: lastMessage?.text || 'Keine Nachrichten',
              timestamp: lastMessage?.timestamp || Date.now(),
              userName: lastMessage?.userName || 'System'
            };
          });
          
          setAllTicketChats(chatArray);
        }
      });

      return () => unsubscribe();
    }
  }, [singleChat, hideSelection, location.pathname]);

  const fetchUserDetails = useCallback(async (uid) => {
    if (!uid || userDetails[uid]) return;

    try {
      const userDoc = await getDoc(doc(db, 'User', uid));
      let userData;
      
      if (userDoc.exists()) {
        userData = userDoc.data();
      } else {
        const consultantDoc = await getDoc(doc(db, 'consultants', uid));
        if (consultantDoc.exists()) {
          userData = consultantDoc.data();
        }
      }

      if (userData) {
        setUserDetails(prev => ({
          ...prev,
          [uid]: {
            name: userData.displayName || userData.email || 'Unbekannter Benutzer',
            role: userData.role || 'user',
            avatar: userData.photoURL || null
          }
        }));
      }
    } catch (error) {
      console.error('Fehler beim Laden der User Details:', error);
    }
  }, [userDetails]);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    const loadUserDetails = async (userId) => {
      if (!userId || userDetails[userId]) return;
      
      try {
        const userDoc = await getDoc(doc(db, 'User', userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserDetails(prev => ({
            ...prev,
            [userId]: {
              name: userData.firstName || 'Unbekannt',
              role: userData.role || 'user'
            }
          }));
        }
      } catch (error) {
        console.error('Fehler beim Laden der Benutzerdaten:', error);
      }
    };

    const newUserIds = messages
      .filter(msg => msg?.userId && !userDetails[msg.userId])
      .map(msg => msg.userId);
    
    const uniqueNewIds = [...new Set(newUserIds)];
    
    uniqueNewIds.forEach(loadUserDetails);
    
  }, [messages, userDetails]);

  useEffect(() => {
    const checkAccess = async () => {
      if (!ticketId || !currentUser) {
        setHasAccess(false);
        return;
      }

      try {
        const ticketDoc = await getDoc(doc(db, 'Tickets', ticketId));
        
        if (!ticketDoc.exists()) {
          setHasAccess(false);
          return;
        }

        const ticketData = ticketDoc.data();
        
        // Prüfe verschiedene Berechtigungsfälle
        const hasAccess = 
          // Ticket-Ersteller
          ticketData.createdBy === currentUser.uid ||
          // Zugewiesener Consultant
          ticketData.assignedTo === currentUser.uid ||
          // Zusätzliche Consultants
          (ticketData.additionalConsultants && ticketData.additionalConsultants.includes(currentUser.uid)) ||
          // Admin oder Consultant generell
          userRole === 'Admin' || 
          userRole === 'consultant';

        setHasAccess(hasAccess);
        
      } catch (error) {
        console.error('Fehler beim Prüfen der Berechtigung:', error);
        setHasAccess(false);
      }
    };

    checkAccess();
  }, [ticketId, currentUser, userRole]);

  const displayName = useCallback((message) => {
    if (message.userId === currentUser?.uid) return 'Sie';
    if (!message.userId) return 'System';
    return userDetails[message.userId]?.name || 'Unbekannt';
  }, [currentUser?.uid, userDetails]);

  useEffect(() => {
    const loadTicketTitle = async () => {
      if (!ticketId) return;
      
      try {
        const ticketDoc = await getDoc(doc(db, 'Tickets', ticketId));
        if (ticketDoc.exists()) {
          const data = ticketDoc.data();
          setTicketTitle(data.title || `Ticket #${ticketId}`);
        } else {
          setTicketTitle(`Ticket #${ticketId}`);
        }
      } catch (error) {
        console.error('Fehler beim Laden des Ticket-Titels:', error);
        setTicketTitle(`Ticket #${ticketId}`);
      }
    };

    loadTicketTitle();
  }, [ticketId]);

  const fetchConsultants = async () => {
    try {
      const consultantsRef = collection(db, 'consultants');
      const snapshot = await getDocs(consultantsRef);
      const consultantsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setConsultants(consultantsList);
    } catch (error) {
      console.error('Fehler beim Laden der Consultants:', error);
    }
  };

  const handleAddConsultant = async () => {
    if (!selectedConsultant || !ticketId) return;

    try {
      const ticketRef = doc(db, 'Tickets', ticketId);
      await updateDoc(ticketRef, {
        additionalConsultants: arrayUnion(selectedConsultant)
      });

      const chatRef = ref(realTimeDb, `chats/${ticketId}`);
      const newMessageRef = push(chatRef);
      await set(newMessageRef, {
        text: `Ein weiterer Consultant wurde zum Chat hinzugefügt.`,
        timestamp: Date.now(),
        type: "system",
        userId: "system",
        userName: "System"
      });

      setOpenAddDialog(false);
      setSelectedConsultant('');
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Consultants:', error);
    }
  };

  const checkNewMessages = useCallback((messages, ticketId) => {
    if (!lastReadTimestamp[ticketId]) {
      setLastReadTimestamp(prev => ({
        ...prev,
        [ticketId]: new Date().toISOString()
      }));
      return;
    }

    const newCount = messages.filter(msg => 
      msg.timestamp > lastReadTimestamp[ticketId] && 
      msg.userId !== currentUser?.uid
    ).length;

    setUnreadMessages(prev => ({
      ...prev,
      [ticketId]: newCount
    }));
  }, [lastReadTimestamp, currentUser?.uid]);

  useEffect(() => {
    if (!ticketId || !hasAccess) return;

    const chatRef = ref(realTimeDb, `chats/${ticketId}`);
    const unsubscribe = onValue(chatRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messageList = Object.entries(data).map(([id, msg]) => ({
          id,
          ...msg
        }));
        setMessages(messageList);
        checkNewMessages(messageList, ticketId);
      }
    });

    return () => unsubscribe();
  }, [ticketId, hasAccess, checkNewMessages]);

  const markAsRead = useCallback((ticketId) => {
    setLastReadTimestamp(prev => ({
      ...prev,
      [ticketId]: new Date().toISOString()
    }));
    setUnreadMessages(prev => ({
      ...prev,
      [ticketId]: 0
    }));
  }, []);

  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    markAsRead(chat.id);
  };

  useEffect(() => {
    const chatRef = ref(realTimeDb, `chats/${ticketId}`);
    let previousLength = messages.length;
    
    const unsubscribe = onValue(chatRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const messageArray = Object.entries(data || {}).map(([id, message]) => ({
          id,
          ...message
        }));
        
        // Sortiere Nachrichten nach Timestamp
        messageArray.sort((a, b) => a.timestamp - b.timestamp);
        
        // Wenn es eine neue Nachricht gibt und sie nicht vom aktuellen Benutzer ist
        if (messageArray.length > previousLength && 
            messageArray[messageArray.length - 1]?.userId !== currentUser?.uid) {
          audio.play().catch(err => console.log('Audio playback failed:', err));
        }
        
        previousLength = messageArray.length;
        setMessages(messageArray);
      }
    });

    return () => unsubscribe();
  }, [ticketId, currentUser?.uid, audio]);

  useEffect(() => {
    const loadTicketDetails = async () => {
      if (!ticketId) return;
      
      setIsLoading(true);
      try {
        const ticketDoc = await getDoc(doc(db, 'Tickets', ticketId));
        if (ticketDoc.exists()) {
          setTicketDetails(ticketDoc.data());
        }
      } catch (error) {
        console.error('Fehler beim Laden der Ticket-Details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadTicketDetails();
  }, [ticketId]);

  // Füge diese Hilfsfunktion hinzu um Anhänge zu rendern
  const renderAttachments = (attachments) => {
    if (!attachments || attachments.length === 0) return null;

    return (
      <Box sx={{ mt: 1 }}>
        {attachments.map((attachment, index) => (
          <Box
            key={index}
            onClick={() => window.open(attachment.url, '_blank')}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              padding: '8px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '4px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.15)'
              },
              mb: 1
            }}
          >
            <AttachFileIcon sx={{ fontSize: '20px' }} />
            <Typography sx={{ fontSize: '0.9rem' }}>
              {attachment.name}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  // Ändern Sie die stripHtmlTags Funktion:
  const stripHtmlTags = (html) => {
    if (!html) return '';
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    // Ersetze <p> Tags mit doppelten Zeilenumbrüchen
    const text = tmp.innerHTML
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '\n\n')
      .replace(/<br\s*\/?>/g, '\n');
    // Entferne alle anderen HTML-Tags
    return text.replace(/<[^>]*>/g, '');
  };

  // Definieren Sie handleFileClick VOR renderMessage
  const handleFileClick = async (file) => {
    try {
      if (!currentUser) {
        throw new Error('Keine Berechtigung - Benutzer nicht angemeldet');
      }

      // Da die URL bereits eine vollständige Download-URL ist, 
      // können wir sie direkt öffnen
      if (file.url) {
        window.open(file.url, '_blank');
      } else {
        throw new Error('Keine gültige Download-URL gefunden');
      }
    } catch (error) {
      console.error('Fehler beim Datei-Download:', error);
      const errorMessage = error.code === 'storage/unauthorized' 
        ? 'Keine Berechtigung zum Download dieser Datei'
        : `Fehler beim Download: ${error.message}`;
      alert(errorMessage);
    }
  };

  // Vereinfachter Image Click Handler
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  // Event Listener für Bildvorschau
  useEffect(() => {
    const handleImagePreview = (e) => {
      handleImageClick(e.detail);
    };

    document.addEventListener('imagePreview', handleImagePreview);
    return () => {
      document.removeEventListener('imagePreview', handleImagePreview);
    };
  }, []);

  // Dann erst renderMessage definieren
  const renderMessage = (message) => {
    const isCurrentUser = message.userId === currentUser?.uid;
    
    // HTML für Bilder im Text mit direktem onClick Handler
    const processMessageText = (text) => {
      if (!text) return '';
      return text.replace(
        /<img(.*?)src="(.*?)"(.*?)>/g,
        (match, p1, src, p3) => `<img${p1}src="${src}"${p3}style="cursor: pointer; max-width: 100%;" onclick="document.dispatchEvent(new CustomEvent('imagePreview', { detail: '${src}' }))"/>`
      );
    };

    // Komplett neue Darstellung ohne Box
    return (
      <div
        style={{
          backgroundColor: isCurrentUser ? '#1976d2' : '#2f3b4c',
          padding: '10px 14px',
          borderRadius: '12px',
          maxWidth: '50%',
          wordBreak: 'break-word',
          alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
          marginBottom: '8px',
          marginLeft: isCurrentUser ? 'auto' : '10px',
          marginRight: isCurrentUser ? '10px' : 'auto',
          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)'
        }}
      >
        {message.text && (
          <div 
            dangerouslySetInnerHTML={{ __html: processMessageText(message.text) }}
            style={{
              color: 'white',
              fontSize: '0.9375rem',
              lineHeight: 1.5
            }}
          />
        )}
        
        {message.attachments && message.attachments.length > 0 && (
          <div style={{ 
            marginTop: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'
          }}>
            {message.attachments.map((file, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  padding: '6px 10px',
                  borderRadius: '8px',
                  cursor: 'pointer'
                }}
                onClick={file.type?.startsWith('image/') ? 
                  () => handleImageClick(file.url) : 
                  () => handleFileClick(file)
                }
              >
                {file.type?.startsWith('image/') ? (
                  <div style={{ 
                    width: '100%',
                    maxWidth: '300px'
                  }}>
                    <img
                      src={file.url}
                      alt={file.name}
                      style={{ 
                        width: '100%',
                        height: 'auto',
                        cursor: 'pointer',
                        borderRadius: '4px'
                      }}
                    />
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <AttachFileIcon style={{ fontSize: '20px' }} />
                    <span style={{ fontSize: '0.9rem', color: 'white' }}>
                      {file.name}
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        
        <div style={{ 
          fontSize: '0.75rem',
          color: 'rgba(255, 255, 255, 0.7)',
          marginTop: '6px',
          textAlign: 'right'
        }}>
          {message.customerName || message.userName} - {formatDate(message.timestamp)}
        </div>
      </div>
    );
  };

  const handleFileUpload = async (file) => {
    if (!currentUser) {
      console.error('Kein authentifizierter Benutzer');
      return;
    }

    try {
      const storageRef = ref(storage, `chat-attachments/${currentUser.uid}/${file.name}`);
      await uploadBytes(storageRef, file);
      // ... weiterer Code
    } catch (error) {
      console.error('Upload Fehler:', error);
      // Fehlerbehandlung
    }
  };

  const Message = ({ message, onFileClick }) => {
    return (
      <div className="message-container">
        {/* Header mit Absender-Info */}
        <div className="message-header">
          <Avatar>
            <PersonIcon />
          </Avatar>
          <div className="sender-info">
            <Typography variant="subtitle1">
              {message.senderName}
            </Typography>
            {message.senderRole && (
              <span className="user-role-badge">
                {message.senderRole}
              </span>
            )}
            <Typography variant="caption" className="message-timestamp">
              {formatDate(message.timestamp)}
            </Typography>
          </div>
        </div>

        {/* Nachrichteninhalt */}
        <div className="message-content">
          {message.content}
        </div>

        {/* Dateianhänge */}
        {message.attachments && message.attachments.length > 0 && (
          <div className="attachments-container">
            {message.attachments.map((file, index) => (
              <div 
                key={index}
                className="attachment-item"
                onClick={() => onFileClick(file)}
              >
                <AttachFileIcon />
                <Typography variant="body2">
                  {file.originalName}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  // Füge diese Funktion zur Bildverarbeitung hinzu
  const handlePaste = async (e) => {
    const clipboardItems = Array.from(e.clipboardData.items);
    const imageItems = clipboardItems.filter(item => item.type.startsWith('image'));
    
    if (imageItems.length > 0) {
      e.preventDefault();
      const imageItem = imageItems[0];
      const blob = imageItem.getAsFile();
      
      try {
        const fileName = `paste-${Date.now()}.png`;
        const storageRef = ref(storage, `chats/${ticketId}/images/${fileName}`);
        const uploadResult = await uploadBytes(storageRef, blob);
        const imageUrl = await getDownloadURL(uploadResult.ref);
        
        // Füge das Bild in den Editor ein
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        editor.insertEmbed(range.index, 'image', imageUrl);
      } catch (error) {
        console.error('Fehler beim Hochladen des Bildes:', error);
      }
    }
  };

  if (!hasAccess) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%',
        color: 'white' 
      }}>
        <Typography>
          Sie haben keine Berechtigung, diesen Chat einzusehen.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      width: '100%', 
      height: '100%', 
      gap: 2,
      overflow: 'hidden'  // Verhindert Overflow des gesamten Containers
    }}>
      {!singleChat && !hideSelection && (
        <Box sx={{ 
          width: '300px', 
          minWidth: '300px',  // Feste Mindestbreite
          borderRight: '1px solid rgba(255,255,255,0.1)' 
        }}>
          <List>
            {allTicketChats.map((chat) => (
              <ListItem 
                key={chat.id}
                onClick={() => handleChatSelect(chat)}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: selectedChat?.id === chat.id ? 'rgba(255, 255, 255, 0.08)' : 'transparent',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' },
                  position: 'relative'
                }}
              >
                <ListItemText 
                  primary={chat.title || `Ticket #${chat.id}`}
                  secondary={`Status: ${chat.status}`}
                />
                {unreadMessages[chat.id] > 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      right: 16,
                      backgroundColor: '#2563eb',
                      color: 'white',
                      borderRadius: '50%',
                      width: 24,
                      height: 24,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '0.75rem'
                    }}
                  >
                    {unreadMessages[chat.id]}
                  </Box>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      
      <Box className="chat-container" sx={{
        flex: '1',  // Nimmt verfügbaren Platz ein
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#111827',
        minWidth: 0  // Erlaubt Schrumpfen
      }}>
        <Box sx={{ 
          p: 2, 
          borderBottom: '1px solid rgba(255,255,255,0.1)',
          backgroundColor: '#1F2937',
          display: 'flex',
          alignItems: 'center',
          gap: 3  // Abstand zwischen den Elementen
        }}>
          <Typography variant="h6" sx={{ color: 'white' }}>
            {ticketDetails?.title || `Ticket #${ticketId}`}
          </Typography>
          
          {userRole === 'consultant' && (
            <Button
              variant="contained"
              onClick={() => {
                fetchConsultants();
                setOpenAddDialog(true);
              }}
              sx={{ 
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              <PersonAddIcon sx={{ mr: 1 }} />
              <Typography sx={{ fontSize: '0.9rem' }}>
                Teilen
              </Typography>
            </Button>
          )}
        </Box>

        <Box 
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#111827',
            borderRadius: '8px',
            overflow: 'hidden'
          }}
        >
          <Box
            ref={messagesContainerRef}
            sx={{
              flex: 1,
              overflowY: 'auto',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              '& .message': {
                maxWidth: '70%',
                alignSelf: 'flex-start',
                '&.sent': {
                  alignSelf: 'flex-end'
                }
              }
            }}
          >
            {Object.entries(groupMessagesByDate(messages))
              .map(([date, dateMessages]) => (
                <React.Fragment key={date}>
                  <Box className="date-separator">
                    <Typography variant="caption" className="date-label">
                      {new Date(dateMessages[0].timestamp).toLocaleDateString('de-DE')}
                    </Typography>
                  </Box>
                  {dateMessages
                    .filter(message => message.text !== 'Ticket erstellt')
                    .map((message, index, array) => {
                      const isFirstMessageOfUser = index === 0 || array[index - 1].userId !== message.userId;
                      
                      return (
                        <React.Fragment key={message.id}>
                          {isFirstMessageOfUser && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                fontSize: '0.8rem',
                                marginLeft: message.userId === currentUser?.uid ? 'auto' : '12px',
                                marginRight: message.userId === currentUser?.uid ? '12px' : 'auto',
                                display: 'block',
                                marginBottom: '4px',
                                marginTop: '12px'
                              }}
                            >
                              {displayName(message)}
                            </Typography>
                          )}
                          
                          {renderMessage(message)}
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              ))}
            <div ref={messagesEndRef} />
          </Box>

          <Box
            sx={{
              padding: '16px',
              borderTop: '1px solid rgba(255,255,255,0.1)',
              position: 'relative',
              backgroundColor: '#1a1f2e',
              height: '300px', // Erhöhte Höhe (doppelt so hoch)
            }}
          >
            <ReactQuill
              ref={quillRef}
              value={message}
              onChange={setMessage}
              modules={modules}
              formats={formats}
              placeholder="Nachricht eingeben..."
              className="custom-quill"
            />
            
            {/* Buttons für Senden und Dateianhänge */}
            <Box sx={{ 
              position: 'absolute',
              bottom: '40px',  // Geändert von '80px' zu '40px', um die Buttons etwas tiefer zu positionieren
              right: '16px',
              display: 'flex',
              gap: '8px',
              backgroundColor: 'rgba(26, 31, 46, 0.7)',  // Leichter Hintergrund für bessere Sichtbarkeit
              padding: '4px',
              borderRadius: '8px',
              zIndex: 10  // Sicherstellen, dass die Buttons über dem Editor angezeigt werden
            }}>
              <IconButton
                onClick={() => fileInputRef.current?.click()}
                sx={{ color: 'white' }}
              >
                <AttachFileIcon />
              </IconButton>
              <IconButton
                onClick={handleSendMessage}
                sx={{ color: 'white' }}
                disabled={isSending}
              >
                <SendIcon />
              </IconButton>
              <input
                type="file"
                multiple
                hidden
                ref={fileInputRef}
                onChange={handleFileSelect}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      
      {!isInTicketView && (
        <Box sx={{
          width: '50%',
          minWidth: '40%',
          backgroundColor: '#1F2937',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}>
          {/* Fixer Header-Bereich */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 2, color: 'white' }}>
              Ticket Details
            </Typography>
            
            <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              Titel
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {ticketDetails?.title || `Ticket #${ticketDetails?.id}`}
            </Typography>

            <Box sx={{ display: 'flex', gap: 4, mb: 2 }}>
              <Box>
                <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  Status
                </Typography>
                <Chip
                  label={ticketDetails?.status}
                  color={
                    ticketDetails?.status === 'offen' ? 'error' :
                    ticketDetails?.status === 'in bearbeitung' ? 'warning' : 'success'
                  }
                  size="small"
                  sx={{ mt: 0.5 }}
                />
              </Box>
              <Box>
                <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  Priorität
                </Typography>
                <Chip
                  label={ticketDetails?.priority}
                  color={
                    ticketDetails?.priority === 'hoch' ? 'error' :
                    ticketDetails?.priority === 'mittel' ? 'warning' : 'info'
                  }
                  size="small"
                  sx={{ mt: 0.5 }}
                />
              </Box>
            </Box>
          </Box>

          {/* Scrollbarer Beschreibungsbereich */}
          <Box sx={{ 
            flex: 1,
            overflowY: 'auto',
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            pt: 2
          }}>
            <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.7)', mb: 1 }}>
              Beschreibung
            </Typography>
            <div 
              dangerouslySetInnerHTML={{ 
                __html: ticketDetails?.description ? 
                  ticketDetails.description.replace(
                    /<img(.*?)src="(.*?)"(.*?)>/g,
                    (match, p1, src, p3) => `<img${p1}src="${src}"${p3}style="cursor: pointer; max-width: 100%; border-radius: 4px; margin: 8px 0;" onclick="document.dispatchEvent(new CustomEvent('imagePreview', { detail: '${src}' }))"/>`
                  ) : 'Keine Beschreibung verfügbar'
              }}
              style={{ 
                fontSize: '1.1rem',
                whiteSpace: 'pre-wrap',
                '& img': {
                  maxWidth: '100%',
                  height: 'auto',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  marginTop: '8px',
                  marginBottom: '8px'
                }
              }}
            />
          </Box>
        </Box>
      )}

      <Dialog 
        open={openAddDialog} 
        onClose={() => setOpenAddDialog(false)}
        PaperProps={{
          style: {
            backgroundColor: '#1F2937',
            color: 'white'
          }
        }}
      >
        <DialogTitle>Consultant hinzufügen</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="consultant-select-label" sx={{ color: 'white' }}>
              Consultant auswählen
            </InputLabel>
            <Select
              labelId="consultant-select-label"
              value={selectedConsultant}
              onChange={(e) => setSelectedConsultant(e.target.value)}
              sx={{
                color: 'white',
                '& .MuiSelect-icon': { color: 'white' }
              }}
            >
              {consultants.map((consultant) => (
                <MenuItem 
                  key={consultant.id} 
                  value={consultant.id}
                  sx={{ color: 'black' }}
                >
                  {consultant.firstName} {consultant.surName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenAddDialog(false)}
            sx={{ color: 'white' }}
          >
            Abbrechen
          </Button>
          <Button 
            onClick={handleAddConsultant}
            variant="contained" 
            disabled={!selectedConsultant}
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      {selectedImage && (
        <ImageModal 
          imageUrl={selectedImage}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
}

export default Chats;
