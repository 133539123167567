import React, { useState } from 'react';
import { auth, db } from '../../firebase/config';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper,
  Alert,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import './Login.css';
import companyLogo from '../../assets/images/logo1.png';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('Login erfolgreich:', user.email);

      const collections = ['SuperAdmin', 'User', 'consultants', 'Corporates'];
      let userData = null;
      let userCollection = '';

      for (const collectionName of collections) {
        console.log('Suche in Collection:', collectionName);
        
        // Direkte Suche nach E-Mail als Dokument-ID
        const directDocRef = doc(db, collectionName, email);
        const directDocSnap = await getDoc(directDocRef);
        
        if (directDocSnap.exists()) {
          userData = directDocSnap.data();
          userCollection = collectionName;
          console.log('Benutzer direkt gefunden in:', collectionName);
          break;
        }

        // Collection durchsuchen
        const collectionRef = collection(db, collectionName);
        const querySnapshot = await getDocs(collectionRef);
        console.log(`Durchsuche ${querySnapshot.size} Dokumente in ${collectionName}`);
        
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          console.log('Prüfe Dokument:', doc.id);
          
          // Prüfe verschiedene mögliche E-Mail-Felder
          if (
            data.email === email || 
            data['E-Mail'] === email || 
            data.emails?.email1 === email ||
            (data.emails && Object.values(data.emails).includes(email))
          ) {
            userData = data;
            userCollection = collectionName;
            console.log('Email gefunden in Dokument:', doc.id);
          }
        });

        if (userData) break;
      }
      
      if (userData) {
        console.log('Gefundene Benutzerdaten:', userData);
        navigate('/dashboard', { replace: true });
      } else {
        console.log('Keine Benutzerdaten gefunden für:', email);
        setError('Benutzerdaten nicht gefunden');
      }
      
    } catch (error) {
      console.error('Login error:', error);
      setError('Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.');
    }

    setLoading(false);
  };

  const handlePasswordReset = async () => {
    if (!resetEmail) {
      setError('Bitte geben Sie eine E-Mail-Adresse ein');
      return;
    }

    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, resetEmail);
      setResetSent(true);
      setShowResetDialog(false);
      // Zeige Erfolgsmeldung
      alert('Eine E-Mail zum Zurücksetzen des Passworts wurde gesendet.');
    } catch (error) {
      setError('Fehler beim Senden der Zurücksetzungs-E-Mail: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container 
      component="main" 
      maxWidth={false}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Box
        sx={{
          padding: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'rgba(30, 41, 59, 0.8)',
          borderRadius: 2,
          boxShadow: 3,
          width: '800px',
          margin: 'auto',
          position: 'relative',
          top: '-5vh'
        }}
      >
        <Box sx={{ mb: 4, width: '450px' }}>
          <img 
            src={companyLogo}
            alt="Smart Resolve Care Logo" 
            style={{ 
              width: '150%', 
              height: 'auto',
              marginBottom: '30px'
            }} 
          />
        </Box>

        <Typography component="h1" variant="h4" sx={{ mb: 4, color: 'white' }}>
          Anmeldung
        </Typography>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

        <form onSubmit={handleLogin}>
          <TextField
            label="E-Mail"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Passwort"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Anmelden'}
          </Button>
        </form>

        {/* Passwort vergessen Link */}
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button
            onClick={() => setShowResetDialog(true)}
            sx={{
              color: 'primary.main',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.04)'
              }
            }}
          >
            Passwort vergessen?
          </Button>
        </Box>

        {/* Passwort zurücksetzen Dialog */}
        <Dialog 
          open={showResetDialog} 
          onClose={() => setShowResetDialog(false)}
          PaperProps={{
            sx: {
              backgroundColor: '#1a1a1a',
              color: 'white',
              minWidth: '300px'
            }
          }}
        >
          <DialogTitle>Passwort zurücksetzen</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="E-Mail-Adresse"
              type="email"
              fullWidth
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.4)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                }
              }}
            />
            {error && (
              <Typography color="error" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setShowResetDialog(false)}
              sx={{ color: 'white' }}
            >
              Abbrechen
            </Button>
            <Button 
              onClick={handlePasswordReset}
              disabled={loading}
              variant="contained"
            >
              Zurücksetzen
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
}

export default Login;
