import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCCmwDgT7L9ddg33RpMGFhwN8G-QtLlZbM",
  authDomain: "smartresolve-care.firebaseapp.com",
  databaseURL: "https://smartresolve-care-default-rtdb.firebaseio.com",
  projectId: "smartresolve-care",
  storageBucket: "smartresolve-care.firebasestorage.app",
  messagingSenderId: "586913023399",
  appId: "1:586913023399:web:d377d6c4eeb98b06e1e282",
  measurementId: "G-2EWQX7ZBGG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const db = getFirestore(app);
const realTimeDb = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, realTimeDb, auth, storage };
export default app;
