import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../firebase/config';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  doc, 
  onSnapshot,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  getDoc
} from 'firebase/firestore';
import { ref, set } from 'firebase/database';
import { realTimeDb } from '../firebase/config';
import { useAuth } from '../contexts/AuthContext';
import { useLocation } from 'react-router-dom';

const TicketContext = createContext();

export function TicketProvider({ children }) {
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const { state } = useLocation();
  
  const [isAdmin, setIsAdmin] = useState(false);
  const [isConsultant, setIsConsultant] = useState(false);

  useEffect(() => {
    const checkUserRole = async () => {
      if (!currentUser?.uid) return;
      
      const consultantDoc = await getDoc(doc(db, 'consultants', currentUser.uid));
      setIsConsultant(consultantDoc.exists());

      const adminDoc = await getDoc(doc(db, 'SuperAdmin', currentUser.uid));
      setIsAdmin(adminDoc.exists());
    };

    checkUserRole();
  }, [currentUser]);

  useEffect(() => {
    const loadTickets = async () => {
      try {
        const ticketsRef = collection(db, 'Tickets');
        const conditions = [];

        // Status-Filter
        if (state?.status) {
          conditions.push(where('status', '==', state.status));
        }

        // Wenn "heute" gefiltert werden soll
        if (state?.timeFilter === 'today') {
          // Startzeit heute 00:00:00
          const startOfDay = new Date();
          startOfDay.setHours(0, 0, 0, 0);

          // Endzeit heute 23:59:59
          const endOfDay = new Date();
          endOfDay.setHours(23, 59, 59, 999);

          conditions.push(where('createdAt', '>=', startOfDay.toISOString()));
          conditions.push(where('createdAt', '<=', endOfDay.toISOString()));
        }

        // Company-Filter für normale User
        if (!isConsultant && !isAdmin && currentUser?.companyId) {
          conditions.push(where('companyId', '==', currentUser.companyId));
        }

        // Kombinierte Query
        const q = query(ticketsRef, ...conditions);

        console.log('Query conditions:', {
          status: state?.status,
          timeFilter: state?.timeFilter,
          conditions: conditions.length
        });

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const ticketData = [];
          snapshot.forEach((doc) => {
            ticketData.push({ id: doc.id, ...doc.data() });
          });
          setTickets(ticketData);
          setLoading(false);
        });

        return unsubscribe;
      } catch (error) {
        console.error('LoadTickets error:', error);
        setLoading(false);
      }
    };

    loadTickets();
  }, [currentUser, state?.status, state?.timeFilter, isAdmin, isConsultant]);

  const generateNextTicketId = async () => {
    try {
      const ticketsRef = collection(db, 'Tickets');
      const q = query(ticketsRef, orderBy('ticketId', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);
      
      let lastNumber = 1;
      
      if (!querySnapshot.empty) {
        const lastTicket = querySnapshot.docs[0].data();
        if (lastTicket.ticketId) {
          lastNumber = parseInt(lastTicket.ticketId.substring(1)) + 1;
        }
      }
      
      return `T${lastNumber.toString().padStart(10, '0')}`;
    } catch (error) {
      throw error;
    }
  };

  const addTicket = async (ticketData) => {
    try {
      console.log('TicketContext - Überprüfung der Namen vor Speicherung:', {
        createdByName: ticketData.createdByName,
        customerName: ticketData.customerName
      });

      const docRef = await addDoc(collection(db, 'Tickets'), ticketData);
      
      const savedDoc = await getDoc(docRef);
      console.log('Gespeicherte Daten:', savedDoc.data());
      
      return docRef.id;
    } catch (error) {
      console.error('Fehler beim Speichern des Tickets:', error);
      throw error;
    }
  };

  const fetchTickets = async () => {
    try {
      setLoading(true);
      const ticketsRef = collection(db, 'Tickets');
      const q = query(ticketsRef, orderBy('ticketId', 'desc'));
      const querySnapshot = await getDocs(q);
      const ticketData = [];
      querySnapshot.forEach((doc) => {
        ticketData.push({ id: doc.id, ...doc.data() });
      });
      setTickets(ticketData);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateTicket = async (ticketId, updatedTicket) => {
    try {
      const ticketRef = doc(db, 'Tickets', ticketId);
      await updateDoc(ticketRef, updatedTicket);
      await fetchTickets();
    } catch (error) {
      throw error;
    }
  };

  const assignConsultant = async (ticketId, consultantId) => {
    try {
      const ticketRef = doc(db, 'Tickets', ticketId);
      
      await updateDoc(ticketRef, {
        assignedTo: consultantId,
        updatedAt: new Date().toISOString(),
        status: 'in Bearbeitung',
        hasChat: true
      });

      const chatRef = ref(realTimeDb, `chats/${ticketId}`);
      await set(chatRef, {
        ticketId: ticketId,
        messages: [],
        participants: [currentUser.uid, consultantId],
        createdAt: new Date().toISOString()
      });

    } catch (error) {
      console.error('Fehler bei der Consultant-Zuweisung:', error);
      throw error;
    }
  };

  return (
    <TicketContext.Provider value={{
      tickets,
      selectedTicket,
      setSelectedTicket,
      addTicket,
      updateTicket,
      fetchTickets,
      loading
    }}>
      {children}
    </TicketContext.Provider>
  );
}

export const useTickets = () => {
  const context = useContext(TicketContext);
  if (!context) {
    throw new Error('useTickets must be used within a TicketProvider');
  }
  return context;
};
