import React, { useState } from 'react';
import { Box, IconButton, Paper, Slider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const ImageModal = ({ imageUrl, onClose }) => {
  const [scale, setScale] = useState(1);
  const minZoom = 0.5;
  const maxZoom = 3;
  const zoomStep = 0.1;

  const handleZoomIn = () => {
    setScale(prev => Math.min(prev + zoomStep, maxZoom));
  };

  const handleZoomOut = () => {
    setScale(prev => Math.max(prev - zoomStep, minZoom));
  };

  const handleReset = () => {
    setScale(1);
  };

  const handleSliderChange = (event, newValue) => {
    setScale(newValue);
  };

  return (
    <Box
      onClick={onClose}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <Paper
        elevation={24}
        onClick={(e) => e.stopPropagation()}
        sx={{
          position: 'relative',
          width: '80%',
          height: '80vh',
          backgroundColor: '#1F2937',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          borderRadius: '8px',
          overflow: 'hidden',
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {/* Kontrollelemente */}
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '4px 8px',
            borderRadius: '20px',
            zIndex: 1,
          }}
        >
          <IconButton
            onClick={handleZoomOut}
            sx={{ color: 'white', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}
          >
            <ZoomOutIcon />
          </IconButton>
          
          <Slider
            value={scale}
            min={minZoom}
            max={maxZoom}
            step={zoomStep}
            onChange={handleSliderChange}
            sx={{
              width: 100,
              color: 'white',
              '& .MuiSlider-thumb': {
                width: 12,
                height: 12,
              },
              '& .MuiSlider-rail': {
                opacity: 0.5,
              }
            }}
          />
          
          <IconButton
            onClick={handleZoomIn}
            sx={{ color: 'white', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}
          >
            <ZoomInIcon />
          </IconButton>
          
          <IconButton
            onClick={handleReset}
            sx={{ color: 'white', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}
          >
            <RestartAltIcon />
          </IconButton>
        </Box>

        {/* Schließen Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        
        {/* Bild Container - cursor entfernt */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            backgroundColor: '#111827',
            overflow: 'auto',
          }}
        >
          <img
            src={imageUrl}
            alt="Vergrößerte Ansicht"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
              borderRadius: '4px',
              transform: `scale(${scale})`,
              transition: 'transform 0.2s ease-in-out',
              cursor: 'default', // Standard Cursor für das Bild
            }}
            onClick={(e) => e.stopPropagation()}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default ImageModal; 