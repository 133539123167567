import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Unauthorized.css';

function Unauthorized() {
  const navigate = useNavigate();

  return (
    <Box className="unauthorized-container">
      <Typography variant="h3" component="h1" gutterBottom>
        Zugriff verweigert
      </Typography>
      <Typography variant="body1" gutterBottom>
        Sie haben keine Berechtigung, auf diese Seite zuzugreifen.
      </Typography>
      <Button 
        variant="contained" 
        onClick={() => navigate(-1)}
        sx={{ mt: 3 }}
      >
        Zurück
      </Button>
    </Box>
  );
}

export default Unauthorized;
