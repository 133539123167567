import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './CreateTicket.css';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  CircularProgress
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useTickets } from './TicketContext';
import { useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useAuth } from '../contexts/AuthContext';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { EncryptionService } from '../services/EncryptionService';
import { FileService } from '../services/FileService';

// Hilfsfunktion zum Bereinigen des Textes vor dem Speichern
const cleanText = (text) => {
  if (!text) return '';
  return text
    .replace(/<[^>]+>/g, '')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'");
};

function CreateTicket() {
  const { currentUser } = useAuth();
  console.log('CreateTicket - Current User:', currentUser);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const { addTicket } = useTickets();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [ticketData, setTicketData] = useState({
    title: '',
    description: '',
    priority: '',
    department: '',
    attachments: [],
    corporateID: 'Corporate01',
    companyId: 'company01',
    ticketId: `T${Date.now()}`,
    internalNotes: '',
    internalPriority: 'hoch',
    createdByRole: 'user',
    assignedTo: ''
  });
  const quillRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState(null);
  const storage = getStorage();

  useEffect(() => {
    console.log('CreateTicket - Current User:', currentUser);
  }, [currentUser]);

  // ResizeObserver Cleanup
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      // Throttle resize events
      if (!window.requestAnimationFrame) {
        return;
      }
      window.requestAnimationFrame(() => {
        // Update layout if needed
      });
    });

    const element = document.querySelector('.form-paper');
    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleChange = (field) => (event) => {
    setTicketData({
      ...ticketData,
      [field]: event.target.value
    });
  };

  const handleFileChange = (files) => {
    const fileArray = Array.from(files);
    setSelectedFiles(prev => [...prev, ...fileArray]);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!currentUser) {
        throw new Error('Kein Benutzer angemeldet');
      }

      // Nur diese neue Logik für Consultant-Berechtigung hinzufügen
      let userCompanyId = currentUser.companyId;
      const consultantDoc = await getDoc(doc(db, 'consultants', currentUser.uid));
      if (consultantDoc.exists()) {
        userCompanyId = consultantDoc.data().companyId;
      }

      if (!userCompanyId) {
        throw new Error('Keine CompanyId gefunden');
      }

      // Sequentielles Hochladen mit bestehender Verschlüsselung
      const uploadedFiles = [];
      for (const file of selectedFiles) {
        try {
          const encryptedData = await EncryptionService.encryptFile(file);
          const sanitizedFileName = file.name.replace(/\s+/g, '_');
          const encryptedFileName = `${Date.now()}_${sanitizedFileName}`;
          const storageRef = ref(storage, `tickets/encrypted/${encryptedFileName}`);

          await uploadBytes(storageRef, encryptedData.blob);
          const downloadURL = await getDownloadURL(storageRef);

          uploadedFiles.push({
            name: file.name,
            encryptedName: encryptedFileName,
            url: downloadURL,
            size: file.size,
            type: file.type,
            uploadedAt: new Date().toISOString(),
            isEncrypted: true,
            originalType: file.type,
          });
        } catch (uploadError) {
          console.error('Fehler beim Hochladen:', uploadError);
          throw new Error(`Fehler beim Hochladen der Datei "${file.name}": ${uploadError.message}`);
        }
      }

      const timestamp = new Date().toISOString();
      let nextNumber = 1; // Standardwert falls noch keine Tickets existieren

      try {
        // Höchste bisherige Ticketnummer finden
        const ticketsRef = await db.collection('tickets')
          .orderBy('ticketNumber', 'desc')
          .limit(1)
          .get();
        
        if (!ticketsRef.empty) {
          const lastTicket = ticketsRef.docs[0].data();
          // Ticketnummer extrahieren, falls vorhanden
          if (lastTicket.ticketNumber) {
            // Format "T-0000001" verarbeiten
            const matches = lastTicket.ticketNumber.match(/T-(\d+)/);
            if (matches && matches[1]) {
              const lastNumber = parseInt(matches[1]);
              nextNumber = lastNumber + 1;
            }
          }
        }
      } catch (error) {
        console.warn('Fehler beim Abrufen der letzten Ticketnummer:', error);
        // Bei Fehler einfach mit 1 beginnen
      }

      // Neue Ticketnummer formatieren mit führenden Nullen (7 Stellen)
      const formattedNumber = nextNumber.toString().padStart(7, '0');
      const ticketNumber = `T-${formattedNumber}`;

      const newTicket = {
        title: ticketData.title,
        description: ticketData.description,
        priority: ticketData.priority || 'low',
        department: ticketData.department || 'finance',
        attachments: uploadedFiles,
        companyId: userCompanyId,
        createdAt: timestamp,
        createdBy: currentUser.uid,
        createdByEmail: currentUser.email,
        createdByName: `${currentUser.firstName || ''} ${currentUser.surName || ''}`.trim(),
        createdByRole: currentUser.role || 'user',
        hasChat: false,
        internalNotes: '',
        internalPriority: 'normal',
        status: 'offen',
        ticketNumber: ticketNumber,
        ticketId: ticketNumber,
        updatedAt: timestamp,
        phoneNumber: ticketData.phoneNumber || '',
        assignmentHistory: [{
          assignedTo: '',
          assignedBy: currentUser.uid,
          timestamp: timestamp,
          action: 'created'
        }],
        auftragsnummer: `T-${formattedNumber}`,
      };

      await addTicket(newTicket);
      navigate('/tickets');
    } catch (error) {
      console.error('Fehler beim Erstellen des Tickets:', error);
      setError(error.message || 'Fehler beim Erstellen des Tickets');
    } finally {
      setLoading(false);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet'
  ];

  const editorConfig = {
    height: '400px',
    skin: "oxide-dark",
    content_css: "dark",
    body_class: 'dark-editor-body',
    content_style: `
      .dark-editor-body {
        background-color: #1f2937 !important;
        min-height: 400px !important;
      }
      body {
        background-color: #1f2937 !important;
        min-height: 400px !important;
        margin: 0 !important;
        padding: 1rem !important;
      }
      p {
        margin: 0 !important;
        color: #fff !important;
        background-color: #1f2937 !important;
      }
    `,
    setup: (editor) => {
      editor.on('init', () => {
        editor.getDoc().body.style.backgroundColor = '#1f2937';
        editor.getWin().document.documentElement.style.backgroundColor = '#1f2937';
      });
    },
    plugins: [
      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
      'searchreplace', 'visualblocks', 'code', 'fullscreen',
      'insertdatetime', 'media', 'table', 'help', 'wordcount'
    ],
    toolbar: 'undo redo | formatselect | ' +
      'bold italic | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist | ' +
      'removeformat',
  };

  const handleUpload = async () => {
    try {
      const uploadedFiles = await Promise.all(
        selectedFiles.map(async (fileObj) => {
          const encryptedFile = await EncryptionService.encryptFile(fileObj.file);
          const path = `tickets/${Date.now()}_${fileObj.name}`;
          const url = await FileService.uploadEncryptedFile(encryptedFile, path);
          return {
            name: fileObj.name,
            type: fileObj.type,
            size: fileObj.size,
            url: url
          };
        })
      );
      return uploadedFiles;
    } catch (error) {
      console.error('Fehler beim Datei-Upload:', error);
      throw error;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const container = document.querySelector('.create-ticket-container');
      if (!container) return;

      const viewportWidth = window.innerWidth - 80; // Padding berücksichtigen
      const baseWidth = 1200; // Basis-Breite
      
      const scale = Math.min(viewportWidth / baseWidth, 1);
      container.style.transform = `scale(${scale})`;
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box sx={{ 
      width: '100%',
      height: 'calc(100vh - 64px)', // Höhe abzüglich der Navbar
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      padding: '1rem'
    }}>
      <form 
        onSubmit={handleSubmit} 
        className="form-grid"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: '100%',
          height: '100%',
          padding: '20px',
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          backdropFilter: 'blur(8px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '8px'
        }}
      >
        <TextField
          fullWidth
          label="Titel"
          name="title"
          value={ticketData.title}
          onChange={handleChange('title')}
          required
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="priority-label">Priorität</InputLabel>
              <Select
                labelId="priority-label"
                value={ticketData.priority}
                label="Priorität"
                onChange={handleChange('priority')}
              >
                <MenuItem value="low">Niedrig</MenuItem>
                <MenuItem value="medium">Mittel</MenuItem>
                <MenuItem value="high">Hoch</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="department-label">Kategorie</InputLabel>
              <Select
                labelId="department-label"
                value={ticketData.department}
                label="Kategorie"
                onChange={handleChange('department')}
              >
                <MenuItem value="BLINK">BLINK</MenuItem>
                <MenuItem value="BSS Service pro">BSS Service pro</MenuItem>
                <MenuItem value="DISPO">DISPO</MenuItem>
                <MenuItem value="FAKTURA">FAKTURA</MenuItem>
                <MenuItem value="FIBU">FIBU</MenuItem>
                <MenuItem value="Lohn BSS service pro">Lohn BSS service pro</MenuItem>
                <MenuItem value="Lohn DATEV">Lohn DATEV</MenuItem>
                <MenuItem value="Lohn NAPA">Lohn NAPA</MenuItem>
                <MenuItem value="NAV/BC Standard">NAV/BC Standard</MenuItem>
                <MenuItem value="OM">OM</MenuItem>
                <MenuItem value="OP-PLUS">OP-PLUS</MenuItem>
                <MenuItem value="Programmanpassungen">Programmanpassungen</MenuItem>
                <MenuItem value="REPORTS">Reports</MenuItem>
                <MenuItem value="TECHNIK">Technik</MenuItem>
                <MenuItem value="VERTRIEB">Vertrieb</MenuItem>
                <MenuItem value="windream">Windream</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ flex: 1, minHeight: '400px' }}>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={ticketData.description}
            onChange={(content) => {
              setTicketData(prev => ({
                ...prev,
                description: content
              }));
            }}
            modules={modules}
            formats={formats}
            className="description-editor"
            style={{ height: '100%' }}
          />
        </Box>

        {/* Datei-Upload Button und Dateiliste */}
        <Box sx={{ width: '100%' }}>
          <Button
            variant="outlined"
            component="label"
            fullWidth
            sx={{ mb: 2 }}
          >
            Dateien anhängen
            <input
              type="file"
              multiple
              hidden
              onChange={(e) => handleFileChange(e.target.files)}
            />
          </Button>

          {/* Minimale Dateiliste */}
          {selectedFiles.length > 0 && (
            <Box sx={{ 
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              mb: 2 
            }}>
              {selectedFiles.map((file, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <InsertDriveFileIcon sx={{ fontSize: 24 }} />
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                  </Typography>
                  <IconButton 
                    size="small"
                    onClick={() => handleRemoveFile(index)}
                    sx={{ padding: 0.5 }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
        </Box>

        {/* Telefonnummer und Buttons in einer Reihe */}
        <Box sx={{ 
          display: 'flex', 
          gap: 2,
          alignItems: 'center',
          width: '100%',
          mt: 'auto' // Schiebt die Box ans Ende des Formulars
        }}>
          <TextField
            label="Telefonnummer"
            name="phoneNumber"
            value={ticketData.phoneNumber || ''}
            onChange={(e) => setTicketData({ ...ticketData, phoneNumber: e.target.value })}
            sx={{ flex: 1 }}
          />
          
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{ minWidth: '120px' }}
          >
            {loading ? <CircularProgress size={24} /> : 'Erstellen'}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setTicketData({
                title: '',
                description: '',
                priority: '',
                department: '',
                attachments: [],
                corporateID: 'Corporate01',
                companyId: 'company01',
                ticketId: `T${Date.now()}`,
                internalNotes: '',
                internalPriority: 'hoch',
                createdByRole: 'user',
                assignedTo: 'testconsultant'
              });
              setSelectedFiles([]);
            }}
            sx={{ minWidth: '120px' }}
          >
            Zurücksetzen
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default CreateTicket;