import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export function NotificationProvider({ children }) {
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [desktopNotificationsEnabled, setDesktopNotificationsEnabled] = useState(true);

  const toggleSound = () => setSoundEnabled(prev => !prev);
  const toggleDesktopNotifications = () => setDesktopNotificationsEnabled(prev => !prev);

  const requestNotificationPermission = async () => {
    if (!("Notification" in window)) return false;
    
    if (Notification.permission === "default") {
      const permission = await Notification.requestPermission();
      return permission === "granted";
    }
    
    return Notification.permission === "granted";
  };

  return (
    <NotificationContext.Provider value={{
      soundEnabled,
      desktopNotificationsEnabled,
      toggleSound,
      toggleDesktopNotifications,
      requestNotificationPermission
    }}>
      {children}
    </NotificationContext.Provider>
  );
}

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};