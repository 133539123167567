import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTickets } from '../components/TicketContext.js';
import { useAuth } from '../contexts/AuthContext';

function HeroSection() {
  const { tickets } = useTickets() || { tickets: [] };
  const ticketList = tickets || [];
  const { currentUser } = useAuth();
  
  const openTickets = ticketList.filter(ticket => ticket?.status?.toLowerCase() === 'offen').length;
  const inProgressTickets = ticketList.filter(ticket => ticket?.status?.toLowerCase() === 'in bearbeitung').length;
  const closedTickets = ticketList.filter(ticket => ticket?.status?.toLowerCase() === 'geschlossen').length;

  return (
    <Box sx={{ 
      padding: '1rem',
      background: 'linear-gradient(to right, rgb(17, 24, 39), rgb(29, 78, 216))',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '60px',
    }}>
      <Typography variant="h5">Ticket Management</Typography>
      <Box sx={{ 
        display: 'flex', 
        gap: '2rem',
        alignItems: 'center'
      }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">{openTickets}</Typography>
          <Typography variant="body2">Offene Tickets</Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">{inProgressTickets}</Typography>
          <Typography variant="body2">In Bearbeitung</Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">{closedTickets}</Typography>
          <Typography variant="body2">Geschlossen</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default HeroSection;
