import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  IconButton,
  Grid,
  Paper,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import TitleIcon from '@mui/icons-material/Title';
import ChatIcon from '@mui/icons-material/Chat';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HistoryIcon from '@mui/icons-material/History';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import TableChartIcon from '@mui/icons-material/TableChart';
import { useAuth } from '../contexts/AuthContext';
import Chats from './Chats';
import './ConsultantTicketView.css';
import { doc, updateDoc, collection, getDocs, query, where, getDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase/config';
import { ref, push, set, serverTimestamp } from 'firebase/database';
import { realTimeDb } from '../firebase/config';
import CryptoJS from 'crypto-js';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { EncryptionService } from '../services/EncryptionService';
import { FileService } from '../services/FileService';
import { downloadFile } from '../services/DownloadHelper';
import DownloadIcon from '@mui/icons-material/Download';
import ImageModal from './ImageModal';

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || 'your-secure-key';

function ConsultantTicketView({ ticket, onTicketUpdate }) {
  const { currentUser, userRole } = useAuth();
  const [showChat, setShowChat] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [consultants, setConsultants] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  
  // State für Edit-Daten
  const [editData, setEditData] = useState({
    assignedTo: ticket?.assignedTo || '',
    internalPriority: ticket?.internalPriority || 'mittel',
    internalNotes: ticket?.internalNotes || ''
  });

  // Berechtigungsprüfung für den Edit-Button
  const hasEditPermission = ['Admin', 'consultant'].includes(userRole);

  const hasConsultantAccess = ['Admin', 'consultant'].includes(userRole);

  const storage = getStorage();

  useEffect(() => {
    const fetchConsultants = async () => {
      try {
        const consultantsRef = collection(db, 'consultants');
        const consultantsSnapshot = await getDocs(consultantsRef);
        const consultantData = {};
        
        consultantsSnapshot.forEach(doc => {
          const data = doc.data();
          consultantData[doc.id] = data.firstName && data.surName 
            ? `${data.firstName} ${data.surName}`
            : data.firstName || data.surName || 'Unbekannt';
        });
        
        setConsultants(consultantData);
      } catch (error) {
        console.error('Fehler beim Laden der Consultants:', error);
      }
    };

    fetchConsultants();
  }, []);

  const handleEditSave = async () => {
    try {
      const ticketRef = doc(db, 'Tickets', ticket.id);
      const updateData = {
        internalPriority: editData.internalPriority,
        internalNotes: editData.internalNotes,
        updatedAt: new Date().toISOString()
      };

      // Wenn ein Consultant ausgewählt wurde und sich die Zuweisung geändert hat
      if (editData.assignedTo !== ticket.assignedTo) {
        // Neue Historien-Eintrag erstellen
        const historyEntry = {
          assignedTo: editData.assignedTo,
          assignedBy: currentUser.uid,
          timestamp: new Date().toISOString(),
          action: 'assigned'
        };

        updateData.assignedTo = editData.assignedTo;
        updateData.status = editData.assignedTo ? 'in Bearbeitung' : 'offen';
        updateData.assignmentHistory = arrayUnion(historyEntry);
      }

      await updateDoc(ticketRef, updateData);
      
      if (onTicketUpdate) {
        onTicketUpdate();
      }
      
      setEditDialogOpen(false);
    } catch (error) {
      console.error('Fehler beim Speichern:', error);
    }
  };

  const handleAssignmentUpdate = async () => {
    try {
      const ticketRef = doc(db, 'Tickets', ticket.id);
      
      // Neue Historien-Eintrag erstellen
      const historyEntry = {
        assignedTo: editData.assignedTo,
        assignedBy: currentUser.uid,
        timestamp: new Date().toISOString(),
        action: 'assigned'
      };

      // Aktualisiere das Ticket mit dem neuen Verlauf
      await updateDoc(ticketRef, {
        assignedTo: editData.assignedTo,
        assignmentHistory: arrayUnion(historyEntry),
        // ... other fields being updated ...
      });

      // ... rest of existing update logic ...
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Zuweisung:', error);
    }
  };

  const handleTicketClose = async () => {
    try {
      const ticketRef = doc(db, 'Tickets', ticket.id);
      
      // Neuer Historien-Eintrag für das Schließen
      const historyEntry = {
        action: 'closed',
        timestamp: new Date().toISOString(),
        assignedBy: currentUser.uid, // Wer das Ticket geschlossen hat
        status: 'geschlossen'
      };

      // Aktualisiere das Ticket
      await updateDoc(ticketRef, {
        status: 'geschlossen',
        closedAt: new Date().toISOString(),
        closedBy: currentUser.uid,
        assignmentHistory: arrayUnion(historyEntry)
      });

      if (onTicketUpdate) {
        onTicketUpdate();
      }
    } catch (error) {
      console.error('Fehler beim Schließen des Tickets:', error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  const handleFileClick = async (file) => {
    try {
      const fileRef = storageRef(storage, file.url);
      const downloadURL = await getDownloadURL(fileRef);

      const response = await fetch(downloadURL);
      if (!response.ok) {
        throw new Error(`Fehler beim Herunterladen der Datei: ${response.statusText}`);
      }
      const encryptedBlob = await response.blob();
      
      // Verwende originalType mit Fallback
      const decryptedBlob = await EncryptionService.decryptFile(
        encryptedBlob,
        file.originalType || 'application/octet-stream'
      );
      
      // Erstelle Download URL
      const url = URL.createObjectURL(decryptedBlob);
      
      // Erstelle Download Link mit originalem Dateinamen
      const a = document.createElement('a');
      a.href = url;
      a.download = file.originalName || 'downloaded-file';
      document.body.appendChild(a);
      a.click();
      
      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download fehlgeschlagen:', {
        error,
        fileInfo: {
          name: file.originalName,
          type: file.originalType,
          path: file.path
        }
      });
      alert(`Fehler beim Download von ${file.originalName}: ${error.message}`);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  // Event Listener für Bildvorschau
  useEffect(() => {
    const handleImagePreview = (e) => {
      handleImageClick(e.detail);
    };

    document.addEventListener('imagePreview', handleImagePreview);
    return () => {
      document.removeEventListener('imagePreview', handleImagePreview);
    };
  }, []);

  // Modifizierte createMarkup Funktion
  const createMarkup = (description) => {
    if (!description) return { __html: '' };
    
    // Füge onClick Handler zu allen img Tags hinzu
    const modifiedDescription = description.replace(
      /<img(.*?)src="(.*?)"(.*?)>/g,
      (match, p1, src, p3) => `
        <img 
          ${p1}
          loading="lazy"
          src="${src}"
          ${p3}
          style="cursor: pointer; max-width: 100%; border-radius: 4px; margin: 8px 0;"
          onclick="document.dispatchEvent(new CustomEvent('imagePreview', { detail: '${src}' }))"/>`
    );
    
    return { __html: modifiedDescription };
  };

  // Formatierung des Datums für die Historie
  const formatHistoryDate = (timestamp) => {
    return new Date(timestamp).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Render der Historie
  const renderAssignmentHistory = () => {
    if (!ticket.assignmentHistory) return null;

    const getUserName = (userId) => {
      // Prüfe erst, ob es ein Consultant ist
      if (consultants[userId]) {
        return consultants[userId];
      }
      // Wenn es der Ticket-Ersteller ist
      if (userId === ticket.createdBy) {
        return ticket.createdByName || 'Unbekannt';
      }
      // Fallback
      return 'Unbekannt';
    };

    return (
      <List sx={{ width: '100%', bgcolor: 'transparent' }}>
        {ticket.assignmentHistory.map((entry, index) => (
          <ListItem key={index} sx={{ 
            py: 1,
            borderBottom: index !== ticket.assignmentHistory.length - 1 ? '1px solid rgba(255,255,255,0.1)' : 'none'
          }}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>
                  {entry.action === 'created' && 'Ticket erstellt'}
                  {entry.action === 'assigned' && `Zugewiesen an: ${consultants[entry.assignedTo] || 'Unbekannt'}`}
                  {entry.action === 'closed' && 'Ticket geschlossen'}
                  {entry.action === 'reopened' && 'Ticket wieder geöffnet'}
                </Typography>
              }
              secondary={
                <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.6)' }}>
                  {formatHistoryDate(entry.timestamp)} - von {getUserName(entry.assignedBy)}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Box className="ticket-container" sx={{ width: '100%', height: '100%' }}>
      {/* Action Buttons */}
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        p: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '8px',
        margin: '16px',
        width: 'calc(100% - 32px)'
      }}>
        {/* History Icon Button */}
        {hasConsultantAccess && (
          <Tooltip title="Ticketverlauf anzeigen">
            <IconButton
              onClick={() => setShowHistory(true)}
              sx={{
                color: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                  color: 'rgba(255, 255, 255, 0.9)',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        )}

        <Button 
          variant="contained"
          startIcon={<ChatIcon />}
          onClick={() => setShowChat(!showChat)}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            }
          }}
        >
          {showChat ? 'Chat ausblenden' : 'Chat anzeigen'}
        </Button>

        {hasEditPermission && (
          <Button 
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => setEditDialogOpen(true)}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              }
            }}
          >
            Ticket bearbeiten
          </Button>
        )}
      </Box>

      {/* Ticket Content */}
      <Box className="ticket-content" sx={{ width: '100%', padding: '0 16px' }}>
        {showChat ? (
          <Chats
            ticketId={ticket.id}
            singleChat={true}
            hideSelection={true}
            isInTicketView={true}
          />
        ) : (
          <Paper className="ticket-info-box" sx={{ 
            width: '100%',
            backgroundColor: 'rgba(15, 23, 42, 0.4)',
            backdropFilter: 'blur(10px)',
            padding: '20px',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: '8px'
          }}>
            {/* Header mit Titel */}
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              mb: 2,
              p: 2,
              backgroundColor: 'rgba(30, 41, 59, 0.5)',
              borderRadius: '6px',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              height: '60px',
              boxSizing: 'border-box'
            }}>
              <TitleIcon sx={{ 
                color: '#60a5fa', 
                fontSize: '1.5rem', 
                marginRight: '8px',
                flexShrink: 0
              }} />
              <Typography 
                variant="h6" 
                sx={{ 
                  flex: 1, 
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '1rem',
                  color: '#f8fafc',
                  fontWeight: 500
                }}
              >
                 {ticket.title || `Ticket #${ticket.id}`}
              </Typography>
            </Box>

            <Grid container spacing={0} sx={{ width: '100%' }}>
              <Grid item xs={12} md={6} sx={{ boxSizing: 'border-box' }}>
                <Box className="ticket-info-list" sx={{ width: '100%' }}>
                  <Box className="info-row" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0px 8px 0px',
                    gap: '12px',
                    backgroundColor: 'rgba(30, 41, 59, 0.5)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: '6px',
                    marginBottom: '10px',
                    height: '42px',
                    boxSizing: 'border-box',
                    ml: '-9px',
                  }}>
                    <TitleIcon sx={{ color: '#60a5fa', fontSize: '24px', flexShrink: 0 }} />
                    <Typography sx={{ 
                      fontSize: '0.8rem', 
                      color: '#f8fafc',
                      flex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                       {ticket.title || 'Kein Titel'}
                    </Typography>
                  </Box>

                  <Box className="info-row" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0px 8px 0px',
                    gap: '12px',
                    backgroundColor: 'rgba(30, 41, 59, 0.5)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: '6px',
                    marginBottom: '10px',
                    height: '42px',
                    boxSizing: 'border-box',
                    ml: '-9px',
                  }}>
                    <PersonIcon sx={{ color: '#60a5fa', fontSize: '24px', flexShrink: 0 }} />
                    <Typography sx={{ 
                      fontSize: '0.8rem', 
                      color: '#f8fafc',
                      flex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                       {ticket.createdByName || 'Kein Name'}
                    </Typography>
                  </Box>

                  <Box className="info-row" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0px 8px 0px',
                    gap: '12px',
                    backgroundColor: 'rgba(30, 41, 59, 0.5)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: '6px',
                    marginBottom: '10px',
                    height: '42px',
                    boxSizing: 'border-box',
                    ml: '-9px',
                  }}>
                    <AssignmentIndIcon sx={{ color: '#60a5fa', fontSize: '24px', flexShrink: 0 }} />
                    <Typography sx={{ 
                      fontSize: '0.8rem', 
                      color: '#f8fafc',
                      flex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                       {ticket.assignedTo ? consultants[ticket.assignedTo] || 'Nicht zugewiesen' : 'Nicht zugewiesen'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} sx={{ padding: '0 10px', boxSizing: 'border-box' }}>
                <Box className="ticket-info-list" sx={{ width: '100%' }}>
                  <Box className="info-row" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0px 8px 0px',
                    gap: '12px',
                    backgroundColor: 'rgba(30, 41, 59, 0.5)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: '6px',
                    marginBottom: '10px',
                    height: '42px',
                    boxSizing: 'border-box',
                    ml: '-6px',
                  }}>
                    <AccessTimeIcon sx={{ color: '#60a5fa', fontSize: '24px', flexShrink: 0 }} />
                    <Typography sx={{ 
                      fontSize: '0.8rem', 
                      color: '#f8fafc',
                      flex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                       {formatDate(ticket.createdAt)}
                    </Typography>
                  </Box>

                  <Box className="info-row" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0px 8px 0px',
                    gap: '12px',
                    backgroundColor: 'rgba(30, 41, 59, 0.5)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: '6px',
                    marginBottom: '10px',
                    height: '42px',
                    boxSizing: 'border-box',
                    ml: '-6px',
                  }}>
                    <InfoIcon sx={{ color: '#60a5fa', fontSize: '24px', flexShrink: 0 }} />
                    <Typography sx={{ 
                      fontSize: '0.8rem', 
                      color: '#f8fafc',
                      flex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                       {ticket.priority || 'Keine'} |  {ticket.status || 'Keiner'}
                    </Typography>
                  </Box>

                  <Box className="info-row" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0px 8px 0px',
                    gap: '12px',
                    backgroundColor: 'rgba(30, 41, 59, 0.5)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: '6px',
                    marginBottom: '10px',
                    height: '42px',
                    boxSizing: 'border-box',
                    ml: '-6px',
                  }}>
                    <EmailIcon sx={{ color: '#60a5fa', fontSize: '24px', flexShrink: 0 }} />
                    <Typography sx={{ 
                      fontSize: '0.8rem', 
                      color: '#f8fafc',
                      flex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                       {ticket.createdByEmail ? (
                        <>
                          <a href={`mailto:${ticket.createdByEmail}`} style={{ color: '#60a5fa', textDecoration: 'none' }}>
                            {ticket.createdByEmail}
                          </a>
                          {ticket.phoneNumber && (
                            <>
                              {' | '}
                              <a href={`tel:${ticket.phoneNumber}`} style={{ color: '#60a5fa', textDecoration: 'none' }}>
                                {ticket.phoneNumber}
                              </a>
                            </>
                          )}
                        </>
                      ) : (
                        'Keine Kontaktdaten'
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* Anhänge Bereich */}
            {ticket.attachments && ticket.attachments.length > 0 && (
              <Box sx={{
                marginBottom: '20px',
                padding: '8px 12px',
                backgroundColor: 'rgba(30, 41, 59, 0.5)',
                borderRadius: '6px',
                border: '1px solid rgba(255, 255, 255, 0.1)'
              }}>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    mb: 1,
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    color: '#f8fafc'
                  }}
                >
                  Anhänge ({ticket.attachments.length})
                </Typography>
                
                <Box sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap', 
                  gap: '12px'
                }}>
                  {ticket.attachments.map((attachment, index) => {
                    // Funktion um den Dateityp zu bestimmen
                    const getFileIcon = (filename) => {
                      if (filename.match(/\.(jpeg|jpg|png|gif|bmp)$/i)) {
                        return <ImageIcon />;
                      } else if (filename.match(/\.(pdf)$/i)) {
                        return <PictureAsPdfIcon />;
                      } else if (filename.match(/\.(doc|docx)$/i)) {
                        return <DescriptionIcon />;
                      } else if (filename.match(/\.(xls|xlsx|csv)$/i)) {
                        return <TableChartIcon />;
                      } else {
                        return <InsertDriveFileIcon />;
                      }
                    };
                    
                    // Dateigröße formatieren
                    const formatFileSize = (sizeInKB) => {
                      if (sizeInKB < 1024) {
                        return `${sizeInKB} KB`;
                      } else {
                        return `${(sizeInKB / 1024).toFixed(2)} MB`;
                      }
                    };
                    
                    // Dateiname für die Anzeige kürzen falls nötig
                    const fileName = attachment.name;
                    const fileSize = formatFileSize(attachment.size || 0);
                    
                    return (
                      <Tooltip 
                        key={index}
                        title={
                          <Box sx={{ p: 1 }}>
                            <Typography variant="body2">{fileName}</Typography>
                            <Typography variant="caption">{fileSize}</Typography>
                          </Box>
                        }
                        arrow
                      >
                        <IconButton 
                          onClick={() => handleFileClick(attachment)}
                          sx={{
                            backgroundColor: 'rgba(15, 23, 42, 0.6)',
                            borderRadius: '8px',
                            padding: '8px',
                            color: '#3b82f6',
                            '&:hover': {
                              backgroundColor: 'rgba(30, 41, 59, 0.8)',
                            }
                          }}
                        >
                          {getFileIcon(fileName)}
                        </IconButton>
                      </Tooltip>
                    );
                  })}
                </Box>
              </Box>
            )}

            {/* Beschreibung - bleibt unverändert */}
            <Box sx={{
              marginTop: '20px',
              padding: '16px',
              backgroundColor: '#1a1b26',
              borderRadius: '8px',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              maxHeight: '400px',
              overflowY: 'auto',
              '& img': {  // Styling für alle Bilder
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '4px',
                cursor: 'pointer',
                margin: '8px 0'
              },
              '&::-webkit-scrollbar': {
                width: '8px'
              },
              '&::-webkit-scrollbar-track': {
                background: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '4px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '4px',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.3)'
                }
              }
            }}>
              <div 
                dangerouslySetInnerHTML={createMarkup(ticket.description)}
                style={{
                  color: 'rgba(255, 255, 255, 0.9)',
                  fontSize: '0.875rem',
                  lineHeight: '1.5',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word'
                }}
              />
            </Box>

            {/* Interne Priorität */}
            {hasConsultantAccess && (
              <Box sx={{ 
                mt: 2,
                p: 2, 
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                borderRadius: 1
              }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Interne Priorität
                </Typography>
                <Typography>
                  {ticket.internalPriority || 'Nicht gesetzt'}
                </Typography>
              </Box>
            )}

            {/* Interne Beschreibung */}
            {ticket.internalDescription && (
              <Box sx={{ 
                mt: 2,
                p: 2, 
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                borderRadius: 1,
                '& img': {
                  maxWidth: '100%',
                  height: 'auto',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  margin: '8px 0'
                }
              }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Interne Beschreibung
                </Typography>
                <div 
                  dangerouslySetInnerHTML={createMarkup(ticket.internalDescription)}
                  style={{ 
                    '& p': { margin: 0 }
                  }}
                />
              </Box>
            )}
          </Paper>
        )}
      </Box>

      {/* Edit Dialog */}
      <Dialog 
        open={editDialogOpen} 
        onClose={() => setEditDialogOpen(false)}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#1a1f2e',
            color: '#fff',
            minWidth: '1000px',
            minHeight: '800px',
            borderRadius: '8px',
            maxWidth: 'none'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #2a3142',
          fontSize: '1.2rem',
          padding: '20px 24px'
        }}>
          Ticket bearbeiten
        </DialogTitle>
        <DialogContent sx={{ padding: '24px' }}>
          {/* Zuweisen an */}
          <FormControl 
            fullWidth 
            sx={{ mb: 3 }}
          >
            <InputLabel>Zuweisen an</InputLabel>
            <Select
              value={editData.assignedTo}
              onChange={(e) => setEditData({...editData, assignedTo: e.target.value})}
            >
              <MenuItem value="">Nicht zugewiesen</MenuItem>
              {Object.entries(consultants).map(([id, name]) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Priorität */}
          <FormControl 
            fullWidth 
            sx={{ mb: 3 }}
          >
            <InputLabel>Priorität</InputLabel>
            <Select
              value={editData.priority}
              onChange={(e) => setEditData({...editData, priority: e.target.value})}
            >
              <MenuItem value="Niedrig">Niedrig</MenuItem>
              <MenuItem value="Mittel">Mittel</MenuItem>
              <MenuItem value="Hoch">Hoch</MenuItem>
            </Select>
          </FormControl>

          {/* Interne Anmerkungen - deutlich größer */}
          <TextField
            fullWidth
            multiline
            rows={24}
            variant="outlined"
            label="Interne Anmerkungen"
            value={editData.internalNotes}
            onChange={(e) => setEditData({...editData, internalNotes: e.target.value})}
            sx={{
              mb: 3,
              '& .MuiInputLabel-root': {
                color: '#8b95a5'
              },
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                backgroundColor: '#2a3142',
                minHeight: '500px',
                '& fieldset': {
                  borderColor: '#3a4156'
                },
                '&:hover fieldset': {
                  borderColor: '#4a5169'
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#3b82f6'
                }
              }
            }}
          />

          <DialogActions sx={{ 
            padding: '16px 0 0',
            '& .MuiButton-root': {
              textTransform: 'none',
              borderRadius: '6px',
              padding: '8px 16px'
            }
          }}>
            <Button 
              onClick={() => setEditDialogOpen(false)}
              sx={{
                color: '#8b95a5',
                border: '1px solid #3a4156',
                '&:hover': {
                  backgroundColor: '#2a3142',
                  color: '#fff'
                }
              }}
            >
              Abbrechen
            </Button>
            <Button 
              onClick={handleEditSave}
              sx={{
                backgroundColor: '#3b82f6',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#2563eb'
                }
              }}
            >
              Speichern
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* ImageModal für die Bildvorschau */}
      {selectedImage && (
        <ImageModal 
          imageUrl={selectedImage}
          onClose={handleCloseModal}
        />
      )}

      {/* Historie Dialog */}
      <Dialog
        open={showHistory}
        onClose={() => setShowHistory(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: '#1F2937',
            color: 'white',
            borderRadius: 2
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid rgba(255,255,255,0.1)',
          display: 'flex',
          alignItems: 'center',
          gap: 1 
        }}>
          <HistoryIcon />
          Ticketverlauf
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {renderAssignmentHistory()}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowHistory(false)}
            sx={{ color: 'white' }}
          >
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ConsultantTicketView;
