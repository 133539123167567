import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Avatar, 
  Typography,
  TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import { ref, onValue, get } from 'firebase/database';
import { realTimeDb, db } from '../firebase/config';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import Chats from './Chats';

function ChatOverview() {
  const { chatId } = useParams();
  const [allChats, setAllChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { currentUser, userRole } = useAuth();
  const [userDetails, setUserDetails] = useState({});

  const getTimeElapsed = (createdAt) => {
    const now = new Date();
    const created = new Date(createdAt);
    const diffTime = Math.abs(now - created);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    
    if (diffDays > 0) {
      return `${diffDays} ${diffDays === 1 ? 'Tag' : 'Tagen'}`;
    } else if (diffHours > 0) {
      return `${diffHours} ${diffHours === 1 ? 'Stunde' : 'Stunden'}`;
    } else {
      return 'weniger als 1 Stunde';
    }
  };

  useEffect(() => {
    const loadChats = async () => {
      if (!currentUser?.uid) return;

      try {
        const ticketsRef = collection(db, 'Tickets');
        let q;

        if (userRole === 'consultant') {
          q = query(
            ticketsRef,
            where('status', 'in', ['offen', 'in Bearbeitung']),
            where('assignedTo', '==', currentUser.uid)
          );

          const additionalTicketsQuery = query(
            ticketsRef,
            where('status', 'in', ['offen', 'in Bearbeitung']),
            where('additionalConsultants', 'array-contains', currentUser.uid)
          );

          try {
            const [assignedSnapshot, additionalSnapshot] = await Promise.all([
              getDocs(q),
              getDocs(additionalTicketsQuery)
            ]);

            const allTickets = new Map();
            [...assignedSnapshot.docs, ...additionalSnapshot.docs].forEach(doc => {
              if (!allTickets.has(doc.id)) {
                allTickets.set(doc.id, { id: doc.id, ...doc.data() });
              }
            });

            const tickets = Array.from(allTickets.values());
            setAllChats(tickets);
          } catch (error) {
            console.error('Fehler beim Laden der zugewiesenen Tickets:', error);
          }
        } else {
          q = query(
            ticketsRef,
            where('createdBy', '==', currentUser.uid)
          );
          
          const snapshot = await getDocs(q);
          const tickets = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setAllChats(tickets);
        }
      } catch (error) {
        console.error('Fehler beim Laden der Chats:', error);
      }
    };

    loadChats();

    return () => {
      // Cleanup wenn nötig
    };
  }, [currentUser?.uid, userRole]);

  const fetchUserDetails = async (userId) => {
    if (!userId || userDetails[userId]) return;
    
    try {
      const userDoc = await getDoc(doc(db, 'User', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserDetails(prev => ({
          ...prev,
          [userId]: {
            name: userData.firstName || 'Unbekannter Benutzer',
            company: userData.companyId?.[0] || '-'
          }
        }));
      }
    } catch (error) {
      console.error('Fehler beim Laden der Benutzerdaten:', error);
    }
  };

  useEffect(() => {
    allChats.forEach(chat => {
      if (chat.createdBy) {
        fetchUserDetails(chat.createdBy);
      }
    });
  }, [allChats]);

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ 
        width: 320, 
        borderRight: 1, 
        borderColor: 'divider',
        backgroundColor: '#1a1f2e',
        color: 'white'
      }}>
        <TextField
          fullWidth
          placeholder="Suchen..."
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />,
            sx: {
              color: 'white',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(255, 255, 255, 0.2)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(255, 255, 255, 0.3)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
            }
          }}
          sx={{ 
            p: 2,
            '& .MuiInputBase-input::placeholder': {
              color: 'rgba(255, 255, 255, 0.5)',
              opacity: 1
            },
            '& .MuiInputBase-input': {
              color: 'white'
            }
          }}
        />
        <List>
          {allChats.length > 0 ? (
            allChats.map((chat) => (
              <ListItem 
                key={chat.id} 
                onClick={() => setSelectedChat(chat)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' },
                  height: '100px',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                  padding: '16px',
                }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ width: 48, height: 48 }}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography 
                      sx={{ 
                        color: 'white', 
                        fontWeight: 500,
                        fontSize: '1rem',
                        mb: 1
                      }}
                    >
                      {chat.title || 'Kein Titel'}
                    </Typography>
                  }
                  secondary={
                    <Box sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      <Typography 
                        variant="body2" 
                        component="div"
                        sx={{ 
                          fontSize: '0.9rem',
                          mb: 0.5
                        }}
                      >
                        {userRole === 'consultant' 
                          ? `Erstellt von: ${chat.createdByName || 'Unbekannt'}`
                          : `Status: ${chat.status || 'Unbekannt'}`
                        }
                      </Typography>
                      <Typography 
                        variant="body2"
                        component="div"
                        sx={{ fontSize: '0.9rem' }}
                      >
                        Erstellt vor: {getTimeElapsed(chat.createdAt)}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText 
                primary="Keine Chats verfügbar" 
                secondary="Erstellen Sie ein Ticket um einen Chat zu starten"
              />
            </ListItem>
          )}
        </List>
      </Box>

      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {selectedChat ? (
          <Chats 
            ticketId={selectedChat.id}
            singleChat={true}
            hideSelection={true}
            isInTicketView={false}
          />
        ) : (
          <Box sx={{ 
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Typography color="text.secondary">
              Wählen Sie einen Chat aus
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ChatOverview;