import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Modal, Stack, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, TextField, Chip, Typography } from '@mui/material';
import { useTickets } from './TicketContext';
import HeroSection from './HeroSection';
import ConsultantTicketView from './ConsultantTicketView';
import Split from 'react-split';
import './TicketManager.css';
import { useLocation } from 'react-router-dom';
import { collection, getDocs, updateDoc, doc, query, where, deleteDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useAuth } from '../contexts/AuthContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import HighPriorityIcon from '@mui/icons-material/PriorityHigh';
import MediumPriorityIcon from '@mui/icons-material/ArrowUpward';
import LowPriorityIcon from '@mui/icons-material/ArrowDownward';
import CircleIcon from '@mui/icons-material/Circle';
import EditTicket from './EditTicket';

const StatusCell = ({ status }) => {
  let icon;
  let color;
  let label;
  
  switch(status?.toLowerCase()) {
    case 'in bearbeitung':
      icon = <PendingIcon sx={{ fontSize: 16 }} />;
      color = '#3b82f6'; // blau
      label = 'In Bearbeitung';
      break;
    case 'geschlossen':
      icon = <CheckCircleIcon sx={{ fontSize: 16 }} />;
      color = '#10b981'; // grün
      label = 'Geschlossen';
      break;
    case 'offen':
      icon = <ErrorIcon sx={{ fontSize: 16 }} />;
      color = '#f59e0b'; // orange
      label = 'Offen';
      break;
    default:
      icon = <CircleIcon sx={{ fontSize: 16 }} />;
      color = '#9ca3af'; // grau
      label = status || 'Unbekannt';
  }
  
  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      gap: 1
    }}>
      {icon}
      <Typography sx={{ 
        color, 
        fontSize: '0.85rem'
      }}>
        {label}
      </Typography>
    </Box>
  );
};

function TicketManager() {
  const { tickets, loading } = useTickets();
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { state } = useLocation();
  const { userRole, currentUser, userData } = useAuth();
  const [consultants, setConsultants] = useState({});
  const [contactPersons, setContactPersons] = useState({});
  const [companies, setCompanies] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const canViewDetailedTicket = userRole?.toLowerCase() === 'consultant' || userRole === 'Admin';
  const [clickTimeout, setClickTimeout] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState({
    assignedTo: '',
    internalPriority: '',
    internalNotes: ''
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [editTicketOpen, setEditTicketOpen] = useState(false);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [splitSizes, setSplitSizes] = useState(() => {
    const savedSizes = localStorage.getItem('ticketManagerSplitSizes');
    return savedSizes ? JSON.parse(savedSizes) : [60, 40];
  });

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const consultantsRef = collection(db, 'consultants');
        const consultantsSnapshot = await getDocs(consultantsRef);
        const consultantData = {};
        consultantsSnapshot.forEach(doc => {
          const data = doc.data();
          consultantData[doc.id] = data.firstName && data.surName 
            ? `${data.firstName} ${data.surName}`
            : data.firstName || data.surName || 'Unbekannt';
        });
        console.log('Consultant Map:', consultantData);
        setConsultants(consultantData);

        const usersRef = collection(db, 'User');
        const usersSnapshot = await getDocs(usersRef);
        const contactData = {};
        const companyData = {};
        usersSnapshot.forEach(doc => {
          const data = doc.data();
          contactData[doc.id] = data.Name || '-';
          companyData[doc.id] = data['Company Name'] || '-';
        });
        setContactPersons(contactData);
        setCompanies(companyData);
      } catch (error) {
        console.error('Fehler beim Laden der Metadata:', error);
      }
    };

    fetchMetadata();
  }, []);

  React.useEffect(() => {
    if (selectedTicket && tickets.length > 0) {
      const updatedTicket = tickets.find(t => t.id === selectedTicket.id);
      if (updatedTicket) {
        setSelectedTicket({
          ...updatedTicket,
          ticketId: `T${updatedTicket.id.toString().padStart(8, '0')}`
        });
      }
    }
  }, [tickets, selectedTicket]);

  useEffect(() => {
    if (rowSelectionModel.length > 0) {
      const selectedRow = tickets.find(row => row.id === rowSelectionModel[0]);
      if (selectedRow) {
        setSelectedTicket(selectedRow);
      }
    }
  }, [rowSelectionModel, tickets]);

  useEffect(() => {
    return () => {
      if (clickTimeout) {
        clearTimeout(clickTimeout);
      }
    };
  }, [clickTimeout]);

  const handleRowClick = (params) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      console.log('Doppelklick erkannt');
      console.log('User Role:', userRole);
      console.log('Can View Detailed:', canViewDetailedTicket);
      
      if (canViewDetailedTicket) {
        console.log('Öffne Modal mit Ticket:', params.row);
        setSelectedTicket(params.row);
        setIsModalOpen(true);
      }
    } else {
      setSelectedTicket(params.row);
      
      setClickTimeout(
        setTimeout(() => {
          setClickTimeout(null);
        }, 250)
      );
    }
  };

  const handleEditClick = () => {
    setEditData({
      assignedTo: selectedTicket?.assignedTo || '',
      internalPriority: selectedTicket?.internalPriority || '',
      internalNotes: selectedTicket?.internalNotes || ''
    });
    setEditDialogOpen(true);
  };

  const handleEditSave = async () => {
    try {
      const ticketRef = doc(db, 'Tickets', selectedTicket.id);
      const updateData = {
        assignedTo: editData.assignedTo,  // Direkt den Wert setzen
        internalPriority: editData.internalPriority,
        internalNotes: editData.internalNotes,
        status: 'in Bearbeitung',
        updatedAt: new Date().toISOString()
      };
      
      console.log('Updating ticket with data:', updateData);
      await updateDoc(ticketRef, updateData);
      
      // Aktualisiere das selectedTicket im State
      setSelectedTicket(prev => ({
        ...prev,
        assignedTo: editData.assignedTo,
        status: 'in Bearbeitung',
        updatedAt: new Date().toISOString()
      }));
      
      setEditDialogOpen(false);
    } catch (error) {
      console.error('Fehler beim Speichern:', error);
    }
  };

  const handleDeleteTicket = async (ticketId) => {
    if (window.confirm('Sind Sie sicher, dass Sie dieses Ticket löschen möchten?')) {
      try {
        const ticketRef = doc(db, 'Tickets', ticketId);
        await deleteDoc(ticketRef);
        console.log('Ticket erfolgreich gelöscht');
      } catch (error) {
        console.error('Fehler beim Löschen:', error);
      }
    }
  };

  // Funktion zum Löschen ausgewählter Tickets
  const handleDeleteSelected = async () => {
    try {
      console.log('Lösche ausgewählte Tickets:', selectedRows);
      // Fügen Sie hier die Logik zum Löschen der ausgewählten Tickets hinzu
      setSelectedRows([]); // Liste der ausgewählten Zeilen zurücksetzen
    } catch (error) {
      console.error('Fehler beim Löschen:', error);
    }
  };

  const handleEditTicket = (ticket, event) => {
    event.stopPropagation();
    setSelectedTicket(ticket);
    setEditTicketOpen(true);
  };

  const handleSaveChanges = async (updatedData) => {
    try {
      const ticketRef = doc(db, 'Tickets', selectedTicket.id);
      await updateDoc(ticketRef, updatedData);

      setEditTicketOpen(false);
      console.log('Ticket erfolgreich aktualisiert:', updatedData);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Tickets:', error);
    }
  };

  useEffect(() => {
    let filtered = [...tickets];

    // Erst die Status-Filter aus der Navigation/Dashboard anwenden
    if (state?.status) {
      filtered = filtered.filter(ticket => 
        ticket.status === state.status
      );
    }

    if (state?.assignedTo) {
      filtered = filtered.filter(ticket => 
        ticket.assignedTo === state.assignedTo &&
        ticket.status?.toLowerCase() !== 'geschlossen'
      );
    }

    // Nur wenn kein spezieller Status oder Zuweisung gefiltert wird,
    // zeige alle Tickets außer geschlossene
    if (!state?.status && !state?.assignedTo) {
      filtered = filtered.filter(ticket => 
        ticket.status?.toLowerCase() !== 'geschlossen'
      );
    }

    console.log('Gefilterte Tickets:', filtered.map(t => ({ id: t.id, status: t.status }))); // Debug-Log
    setFilteredTickets(filtered);
  }, [tickets, state]);

  // Stelle sicher, dass beim Laden der Seite ein Ticket ausgewählt wird
  useEffect(() => {
    // Wähle nur ein Ticket aus, wenn keines ausgewählt ist und Tickets verfügbar sind
    if (!selectedTicket && filteredTickets.length > 0) {
      // Wenn ein Ticket in URL/State angegeben ist, wähle dieses
      if (state?.ticketId) {
        const ticketFromState = filteredTickets.find(t => t.id === state.ticketId);
        if (ticketFromState) {
          setSelectedTicket(ticketFromState);
        } else {
          // Wenn angegeben, aber nicht gefunden, wähle erstes Ticket
          setSelectedTicket(filteredTickets[0]);
        }
      } else {
        // Bei keinem angegebenen Ticket, wähle erstes Ticket aus
        setSelectedTicket(filteredTickets[0]);
      }
    }
  }, [filteredTickets, selectedTicket, state]);

  if (!tickets) {
    return <Box>Lade Tickets...</Box>;
  }

  const getStatusIcon = (status) => {
    if (!status) return <CircleIcon sx={{ color: 'grey' }} />;
    
    switch (status.toLowerCase()) {
      case 'offen':
        return <ErrorIcon color="error" />;
      case 'in bearbeitung':
        return <PendingIcon color="warning" />;
      case 'geschlossen':
        return <CheckCircleIcon color="success" />;
      default:
        return <CircleIcon sx={{ color: 'grey' }} />;
    }
  };

  const getPriorityIcon = (priority) => {
    let icon;
    let color;
    let label;

    switch(priority?.toLowerCase()) {
      case 'hoch':
      case 'high':
        icon = <HighPriorityIcon sx={{ fontSize: 16 }} />;
        color = '#ef4444'; // rot
        label = 'Hoch';
        break;
      case 'mittel':
      case 'medium':
        icon = <MediumPriorityIcon sx={{ fontSize: 16 }} />;
        color = '#f59e0b'; // orange
        label = 'Mittel';
        break;
      case 'niedrig':
      case 'low':
        icon = <LowPriorityIcon sx={{ fontSize: 16 }} />;
        color = '#10b981'; // grün
        label = 'Niedrig';
        break;
      default:
        icon = <CircleIcon sx={{ fontSize: 16 }} />;
        color = '#9ca3af'; // grau
        label = priority || 'Unbekannt';
    }

    return (
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1
      }}>
        {icon}
        <Typography sx={{ color, fontSize: '0.85rem' }}>
          {label}
        </Typography>
      </Box>
    );
  };

  const handleCloseConfirm = async () => {
    try {
      if (selectedTicketId) {
        const ticketRef = doc(db, 'Tickets', selectedTicketId);
        const timestamp = new Date().toISOString();
        
        // Neuer Historien-Eintrag für das Schließen
        const historyEntry = {
          action: 'closed',
          timestamp: timestamp,
          assignedBy: currentUser.uid,
          status: 'geschlossen'
        };

        await updateDoc(ticketRef, {
          status: 'geschlossen',
          closedAt: timestamp,
          closedBy: currentUser.uid,
          assignmentHistory: arrayUnion(historyEntry) // Füge den Historien-Eintrag hinzu
        });
        
        setOpenCloseDialog(false);
        setSelectedTicketId(null);
      }
    } catch (error) {
      console.error('Fehler beim Schließen des Tickets:', error);
    }
  };

  const columns = [
    {
      field: 'auftragsnummer',
      headerName: 'Ticket Nr.',
      width: 120,
      renderCell: (params) => {
        if (!params.value) {
          return `AUF${params.row.id.toString().padStart(4, '0')}`;
        }
        return params.value;
      }
    },
    {
      field: 'kunde',
      headerName: 'Kunde',
      width: 120,
      valueGetter: (params) => {
        return companies[params.row.companyId] || params.row.companyId || 'Unbekannt';
      }
    },
    {
      field: 'ansprechpartner',
      headerName: 'Ansprechpartner',
      width: 150,
      valueGetter: (params) => {
        return contactPersons[params.row.createdBy] || params.row.createdByName || 'Unbekannt';
      }
    },
    {
      field: 'kategorie',
      headerName: 'Kategorie',
      width: 120,
      valueGetter: (params) => {
        // Mapping für die Abteilungsnamen
        const departments = {
          'it': 'IT',
          'hr': 'Personal',
          'finance': 'Finanzen',
          'sales': 'Vertrieb',
          'TECHNIK': 'TECHNIK',
          'FAKTURA': 'FAKTURA',
          'IT': 'IT',
          'NAV/BC Standard': 'NAV/BC Standard',
          'Finanzen': 'Finanzen',
          'Programmanpassung': 'Programmanpassung',
          'DISPO': 'DISPO'
        };
        
        // Bestimme die Kategorie, zuerst über department, alternativ über kategorie
        const value = params.row.department || params.row.kategorie;
        return departments[value] || value || 'Keine Kategorie';
      }
    },
    {
      field: 'zugewiesenAn',
      headerName: 'Zugewiesen an',
      width: 140,
      valueGetter: (params) => {
        return consultants[params.row.assignedTo] || params.row.assignedTo || 'Nicht zugewiesen';
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        return <StatusCell status={params.row.status} />;
      }
    },
    {
      field: 'prioritaet',
      headerName: 'Priorität',
      width: 120,
      renderCell: (params) => {
        const priority = params.row.priority;
        return getPriorityIcon(priority);
      }
    },
    {
      field: 'erstelltAm',
      headerName: 'Erstellt am',
      width: 120,
      valueGetter: (params) => {
        const date = new Date(params.row.createdAt);
        return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      }
    },
    {
      field: 'closeTicket',
      headerName: 'Ticket schließen',
      width: 130,
      renderCell: (params) => {
        const handleClose = async (e, ticketId) => {
          e.stopPropagation();
          if (ticketId) {
            setSelectedTicketId(ticketId);
            setOpenCloseDialog(true);
          }
        };

        return params.row.status?.toLowerCase() !== 'geschlossen' ? (
          <Box
            aria-hidden="false"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid rgb(24, 144, 255)',
                borderRadius: '4px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(24, 144, 255, 0.1)'
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '32px',
                margin: '2px'
              }}
              onClick={(e) => handleClose(e, params.row.id)}
            >
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: '0.8rem',
                  fontWeight: 500,
                  userSelect: 'none'
                }}
              >
                Schließen
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                backgroundColor: 'rgba(81, 81, 81, 0.2)',
                border: '1px solid rgba(81, 81, 81, 0.3)',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '32px',
                margin: '2px'
              }}
            >
              <Typography
                sx={{
                  color: 'rgba(255, 255, 255, 0.5)',
                  fontSize: '0.8rem',
                  fontWeight: 500,
                  userSelect: 'none'
                }}
              >
                Geschlossen
              </Typography>
            </Box>
          </Box>
        );
      }
    }
  ];

  return (
    <Box sx={{ 
      height: 'calc(100vh - 64px)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <HeroSection />
      <Box className="ticket-manager" sx={{ 
        height: 'calc(100vh - var(--hero-height))',
        background: 'transparent',
        padding: '1rem'
      }}>
        <Box sx={{ 
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '1rem',
          marginBottom: '1rem',
          background: 'transparent',
          '& > *': {
            background: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(8px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: '8px',
            padding: '1rem'
          }
        }}>
          {/* Die Kacheln bleiben unverändert */}
        </Box>
        <Split 
          sizes={splitSizes} 
          minSize={[320, 200]} 
          expandToMin={false} 
          gutterSize={8} 
          gutterAlign="center" 
          snapOffset={20} 
          dragInterval={1} 
          direction="horizontal" 
          cursor="col-resize"
          className="split-flex"
          onDragEnd={(sizes) => {
            localStorage.setItem('ticketManagerSplitSizes', JSON.stringify(sizes));
            setSplitSizes(sizes);
          }}
        >
          <Box className="ticket-container" sx={{ 
            height: 'calc(100% - 2rem)',
            overflow: 'hidden' 
          }}>
            <DataGrid
              rows={filteredTickets}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              onRowClick={handleRowClick}
              getRowClassName={(params) =>
                params.row.id === selectedTicket?.id ? 'selected-row' : ''
              }
              hideFooterSelectedRowCount={true}
              sx={{
                height: '100%',
                '& .MuiDataGrid-root': {
                  height: '100%',
                  backgroundColor: 'transparent !important'
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  backgroundColor: 'transparent !important',
                  '&:hover': {
                    backgroundColor: 'rgba(30, 41, 59, 0.4) !important'
                  }
                },
                '& .selected-row': {
                  backgroundColor: 'rgba(0, 127, 255, 0.15) !important',
                  '&:hover': {
                    backgroundColor: 'rgba(30, 41, 59, 0.4) !important'
                  }
                },
                '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                  outline: 'none !important',
                  border: 'none !important',
                  boxShadow: 'none !important'
                },
                '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                  outline: 'none !important'
                },
                backgroundColor: 'transparent !important',
                backdropFilter: 'blur(8px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                '& .MuiDataGrid-cell': {
                  color: 'white',
                  backgroundColor: 'transparent !important'
                },
                '& .MuiDataGrid-columnHeaders': {
                  color: 'white',
                  backgroundColor: 'transparent !important',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                },
                '& .MuiDataGrid-virtualScroller': {
                  backgroundColor: 'transparent !important',
                  scrollbarWidth: 'none',  // Firefox
                  '-ms-overflow-style': 'none',  // IE/Edge
                  '&::-webkit-scrollbar': {
                    display: 'none'  // Chrome/Safari/Opera
                  }
                },
                '& .MuiDataGrid-footerContainer': {
                  backgroundColor: 'transparent !important'
                },
                '& .MuiDataGrid-virtualScrollerContent': {
                  backgroundColor: 'transparent !important'
                },
                '& .MuiTablePagination-actions': {
                  display: 'none'
                }
              }}
            />
          </Box>
          
          <Box className="ticket-details" sx={{ 
            height: 'calc(100% - 2rem)',
            overflow: 'hidden' 
          }}>
            {selectedTicket ? (
              <ConsultantTicketView 
                ticket={selectedTicket}
                readOnly={userRole === 'user'}
                hideInternalSection={userRole === 'user'}
                isFullScreen={false}
              />
            ) : (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                Wählen Sie ein Ticket aus
              </Box>
            )}
          </Box>
        </Split>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="ticket-detail-modal"
      >
        <Box className="modal-content">
          {selectedTicket ? (
            <>
              <ConsultantTicketView 
                ticket={selectedTicket}
                isFullScreen={true}
                readOnly={false}
                onClose={() => setIsModalOpen(false)}
              />
            </>
          ) : (
            <Box>Kein Ticket ausgewählt</Box>
          )}
        </Box>
      </Modal>

      {/* Edit Dialog */}
      <Dialog 
        open={editDialogOpen} 
        onClose={() => setEditDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Ticket bearbeiten</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Zuweisen an</InputLabel>
            <Select
              value={editData.assignedTo}
              onChange={(e) => setEditData({...editData, assignedTo: e.target.value})}
              label="Zuweisen an"
            >
              {/* Dynamische Liste der Consultants */}
              {Object.entries(consultants).map(([id, name]) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Interne Priorität</InputLabel>
            <Select
              value={editData.internalPriority}
              onChange={(e) => setEditData({...editData, internalPriority: e.target.value})}
              label="Interne Priorität"
            >
              <MenuItem value="hoch">Hoch</MenuItem>
              <MenuItem value="mittel">Mittel</MenuItem>
              <MenuItem value="niedrig">Niedrig</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Interne Anmerkungen"
            value={editData.internalNotes}
            onChange={(e) => setEditData({...editData, internalNotes: e.target.value})}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Abbrechen</Button>
          <Button onClick={handleEditSave} variant="contained">Speichern</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog für die Ticket-Bearbeitung */}
      <Dialog 
        open={editTicketOpen}
        onClose={() => setEditTicketOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'visible',
          },
        }}
        sx={{
          '& .MuiDialog-paper': {
            margin: '32px auto',
            overflow: 'visible'
          },
          '& .MuiDialog-container': {
            alignItems: 'center',
            justifyContent: 'center',
          }
        }}
      >
        <EditTicket 
          ticket={selectedTicket}
          onClose={() => setEditTicketOpen(false)}
          onSave={handleSaveChanges}
        />
      </Dialog>

      <Dialog
        open={openCloseDialog}
        onClose={() => setOpenCloseDialog(false)}
        PaperProps={{
          style: {
            backgroundColor: '#1a1a1a',
            color: 'white'
          }
        }}
      >
        <DialogTitle>Ticket schließen</DialogTitle>
        <DialogContent>
          Möchten Sie dieses Ticket wirklich schließen?
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenCloseDialog(false)}
            sx={{ color: 'white' }}
          >
            Abbrechen
          </Button>
          <Button 
            onClick={handleCloseConfirm}
            variant="contained" 
            color="primary"
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default TicketManager;
