import React, { useState, useEffect, useRef } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext.js';
import HeroSection from './HeroSection.js';
import './Dashboard.css';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase/config.js';
import companyLogo from '../assets/images/logo2.png';

function Dashboard() {
  const { userRole, currentUser } = useAuth();
  const navigate = useNavigate();
  const [stats, setStats] = useState({
    todayOpenTasks: 0,
    allOpenTasks: 0,
    todayNewTasks: 0,
    unassignedTasks: 0
  });
  
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const ticketsRef = collection(db, 'Tickets');
        const allTicketsSnapshot = await getDocs(ticketsRef);
        
        if (userRole === 'consultant') {
          // Alle aktiven Tickets (offen + in Bearbeitung)
          const activeTickets = allTicketsSnapshot.docs.filter(doc => 
            ['offen', 'in Bearbeitung'].includes(doc.data().status)
          );
          
          // Nur offene Tickets
          const openTickets = allTicketsSnapshot.docs.filter(doc => 
            doc.data().status === 'offen'
          );
          
          // Dem Consultant zugewiesene Tickets
          const assignedTickets = allTicketsSnapshot.docs.filter(doc => 
            doc.data().assignedTo === currentUser?.uid &&
            ['offen', 'in Bearbeitung'].includes(doc.data().status)
          );
          
          setStats(prevStats => ({
            ...prevStats,
            allOpenTasks: activeTickets.length,     // Counter für alle aktiven Tickets
            todayOpenTasks: openTickets.length,     // Counter für nur offene Tickets
            todayNewTasks: assignedTickets.length   // Counter für zugewiesene Tickets
          }));
        } else {
          // Berechnung für offene Tickets des Users
          const myOpenTickets = allTicketsSnapshot.docs.filter(doc => 
            doc.data().createdBy === currentUser?.uid && 
            doc.data().status === 'offen'
          );
          
          // Berechnung für Tickets in Bearbeitung
          const myInProgressTickets = allTicketsSnapshot.docs.filter(doc => 
            doc.data().createdBy === currentUser?.uid && 
            doc.data().status === 'in Bearbeitung'
          );
          
          // Berechnung für geschlossene Tickets
          const myClosedTickets = allTicketsSnapshot.docs.filter(doc => 
            doc.data().createdBy === currentUser?.uid && 
            doc.data().status === 'geschlossen'
          );
          
          // Berechnung für aktive Tickets (offen + in Bearbeitung)
          const myActiveTickets = allTicketsSnapshot.docs.filter(doc => 
            doc.data().createdBy === currentUser?.uid && 
            ['offen', 'in Bearbeitung'].includes(doc.data().status)
          );
          
          setStats(prevStats => ({
            ...prevStats,
            myOpenTickets: myOpenTickets.length,
            myInProgressTickets: myInProgressTickets.length,
            myClosedTickets: myClosedTickets.length,  // Neuer Counter für geschlossene Tickets
            allMyTickets: myActiveTickets.length
          }));
        }
      } catch (error) {
        console.error('Fehler beim Laden der Statistiken:', error);
      }
    };

    fetchStats();
  }, [userRole, currentUser]);

  const showAdminKacheln = ['Admin', 'consultant'].includes(userRole);
  const showUserKacheln = ['user', 'CorporateAdmin'].includes(userRole);

  const cardStyle = {
    p: 3,
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s',
    backgroundColor: '#f5f5f5',
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTypography-root': {
      color: '#1a1f2e !important'
    },
    '&:hover': {
      transform: 'translateY(-3px)',
      boxShadow: 3,
      backgroundColor: '#e0e0e0'
    }
  };

  const numberStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#1a1f2e !important',
    marginTop: '10px'
  };

  const titleStyle = {
    fontSize: '0.9rem',
    fontWeight: 'medium',
    color: '#1a1f2e !important',
    textAlign: 'center',
    lineHeight: 1.2
  };

  const animateValue = (start, end, duration, setValue) => {
    if (start === end) return;
    
    const range = end - start;
    const minFrame = 50;
    
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      
      const elapsed = timestamp - startTimestamp;
      const progress = Math.min(elapsed / duration, 1);

      const easeOutQuad = 1 - Math.pow(1 - progress, 2);
      const currentValue = Math.floor(start + range * easeOutQuad);
      
      setValue(currentValue);

      if (progress < 1) {
        setTimeout(() => {
          window.requestAnimationFrame(step);
        }, minFrame);
      } else {
        setValue(end);
      }
    };

    window.requestAnimationFrame(step);
  };

  const [animatedStats, setAnimatedStats] = useState({
    myOpenTickets: 0,
    myInProgressTickets: 0,
    myClosedTickets: 0,
    allMyTickets: 0
  });

  useEffect(() => {
    const animationDuration = 2000;
    
    if (stats.myOpenTickets !== undefined) {
      animateValue(0, stats.myOpenTickets, animationDuration, (value) => 
        setAnimatedStats(prev => ({ ...prev, myOpenTickets: value }))
      );
    }
    if (stats.myInProgressTickets !== undefined) {
      setTimeout(() => {
        animateValue(0, stats.myInProgressTickets, animationDuration, (value) => 
          setAnimatedStats(prev => ({ ...prev, myInProgressTickets: value }))
        );
      }, 200);
    }
    if (stats.myClosedTickets !== undefined) {
      setTimeout(() => {
        animateValue(0, stats.myClosedTickets, animationDuration, (value) => 
          setAnimatedStats(prev => ({ ...prev, myClosedTickets: value }))
        );
      }, 400);
    }
    if (stats.allMyTickets !== undefined) {
      setTimeout(() => {
        animateValue(0, stats.allMyTickets, animationDuration, (value) => 
          setAnimatedStats(prev => ({ ...prev, allMyTickets: value }))
        );
      }, 600);
    }
  }, [stats]);

  const formatNumber = (num) => {
    return new Intl.NumberFormat('de-DE').format(num);
  };

  const renderDashboardCard = (title, number, onClick, dataTarget) => (
    <div 
      className="dashboard-card" 
      onClick={onClick}
      data-target={dataTarget}
    >
      <div className="card-header">
        <h2>{title}</h2>
      </div>
      <div className="card-content">
        <div className="card-number">
          {formatNumber(typeof number === 'number' ? number : 0)}
        </div>
      </div>
    </div>
  );

  const handleOpenTicketsClick = () => {
    navigate('/tickets', { 
      state: { 
        filter: 'openCompanyUser',
        companyId: currentUser?.companyId,
        userId: currentUser?.uid
      } 
    });
  };

  return (
    <div className="dashboard" style={{ position: 'relative', minHeight: '100vh' }}>
      <HeroSection />
      
      {showAdminKacheln && (
        <Box sx={{ 
          width: '100%', 
          p: 2,
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '1rem'
        }}>
          <Paper 
            sx={cardStyle}
            onClick={() => navigate('/tickets', { 
              state: { 
                filter: 'all'  // Vereinfachter Filter für Consultants
              } 
            })}
          >
            <Typography sx={titleStyle}>
              Alle Tickets
            </Typography>
            <Typography sx={numberStyle}>
              {stats.allOpenTasks}
            </Typography>
          </Paper>

          <Paper 
            sx={cardStyle}
            onClick={() => navigate('/tickets', { 
              state: { 
                filter: 'open',
                status: 'offen'
              } 
            })}
          >
            <Typography sx={titleStyle}>
              Alle offenen Tickets
            </Typography>
            <Typography sx={numberStyle}>
              {stats.todayOpenTasks}
            </Typography>
          </Paper>

          <Paper 
            sx={cardStyle}
            onClick={() => navigate('/tickets', { 
              state: { 
                filter: 'assignedToMe',
                assignedTo: currentUser.uid
              } 
            })}
          >
            <Typography sx={titleStyle}>
              Meine zugewiesenen Tickets
            </Typography>
            <Typography sx={numberStyle}>
              {stats.todayNewTasks}
            </Typography>
          </Paper>

          <Paper 
            sx={cardStyle}
            onClick={() => navigate('/tickets', { 
              state: { 
                filter: 'unassigned',
                status: 'offen',
                assignedTo: null
              } 
            })}
          >
            <Typography sx={titleStyle}>
              Nicht zugewiesene Tickets
            </Typography>
            <Typography sx={numberStyle}>
              {stats.unassignedTasks}
            </Typography>
          </Paper>
        </Box>
      )}

      {showUserKacheln && (
        <Box sx={{ 
          width: '100%', 
          p: 2,
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '1rem'
        }}>
          <Paper 
            sx={cardStyle}
            onClick={() => navigate('/tickets', { 
              state: { 
                filter: 'openCompanyUser',
                createdBy: currentUser?.uid,
                status: 'offen'
              } 
            })}
          >
            <Typography sx={titleStyle}>
              Meine offenen Tickets
            </Typography>
            <Typography sx={numberStyle}>
              {stats.myOpenTickets || 0}
            </Typography>
          </Paper>

          <Paper 
            sx={cardStyle}
            onClick={() => navigate('/tickets', { 
              state: { 
                filter: 'inProgress',
                createdBy: currentUser?.uid,
                status: 'in Bearbeitung'
              } 
            })}
          >
            <Typography sx={titleStyle}>
              Tickets in Bearbeitung
            </Typography>
            <Typography sx={numberStyle}>
              {stats.myInProgressTickets || 0}
            </Typography>
          </Paper>

          <Paper 
            sx={cardStyle}
            onClick={() => navigate('/closed-tickets', { 
              state: { 
                filter: 'closed',
                createdBy: currentUser?.uid
              } 
            })}
          >
            <Typography sx={titleStyle}>
              Geschlossene Tickets
            </Typography>
            <Typography sx={numberStyle}>
              {stats.myClosedTickets || 0}
            </Typography>
          </Paper>

          <Paper 
            sx={cardStyle}
            onClick={() => navigate('/tickets', { 
              state: { 
                filter: 'allCompanyUser',
                createdBy: currentUser?.uid
              } 
            })}
          >
            <Typography sx={titleStyle}>
              Alle meine Tickets
            </Typography>
            <Typography sx={numberStyle}>
              {stats.allMyTickets || 0}
            </Typography>
          </Paper>
        </Box>
      )}

      <div style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000
      }}>
        <img 
          src={companyLogo} 
          alt="Company Logo" 
          style={{
            height: '100px',
            opacity: 0.8,
            transition: 'opacity 0.3s ease',
            ':hover': {
              opacity: 1
            }
          }}
        />
      </div>
    </div>
  );
}

export default Dashboard;
