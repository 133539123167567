import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import './EditTicket.css';

const EditTicket = ({ ticket, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    title: ticket?.title || '',
    department: ticket?.department || '',
    priority: ticket?.priority || '',
    description: ticket?.description || '',
    attachments: ticket?.attachments || []
  });

  const handleChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value
    });
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFormData(prev => ({
      ...prev,
      attachments: [...prev.attachments, ...newFiles]
    }));
  };

  const handleRemoveFile = (index) => {
    setFormData(prev => ({
      ...prev,
      attachments: prev.attachments.filter((_, i) => i !== index)
    }));
  };

  return (
    <Box className="form-paper" sx={{ width: '100%', maxWidth: '500px' }}>
      <Typography variant="h6" className="title">
        Ticket bearbeiten
      </Typography>
      <form>
        <TextField
          fullWidth
          label="Titel"
          value={formData.title}
          onChange={handleChange('title')}
          sx={{ mb: 2 }}
        />
        
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Abteilung</InputLabel>
          <Select
            value={formData.department}
            onChange={handleChange('department')}
          >
            <MenuItem value="IT">IT</MenuItem>
            <MenuItem value="HR">HR</MenuItem>
            <MenuItem value="Finance">Finance</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Priorität</InputLabel>
          <Select
            value={formData.priority}
            onChange={handleChange('priority')}
          >
            <MenuItem value="Niedrig">Niedrig</MenuItem>
            <MenuItem value="Mittel">Mittel</MenuItem>
            <MenuItem value="Hoch">Hoch</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          multiline
          rows={4}
          label="Beschreibung"
          value={formData.description}
          onChange={handleChange('description')}
          sx={{ mb: 2 }}
        />

        <Button
          variant="outlined"
          component="label"
          startIcon={<AttachFileIcon />}
          className="file-button"
          sx={{ mb: 2, width: '100%' }}
        >
          Dateien anhängen
          <input
            type="file"
            multiple
            hidden
            onChange={handleFileChange}
          />
        </Button>

        {/* Dateivorschau */}
        {formData.attachments.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Angehängte Dateien:
            </Typography>
            <Box sx={{ 
              maxHeight: '150px', 
              overflowY: 'auto',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              p: 1
            }}>
              {formData.attachments.map((file, index) => (
                <Box 
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 1,
                    '&:not(:last-child)': {
                      borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                    }
                  }}
                >
                  <Typography variant="body2" sx={{ color: 'white' }}>
                    {file.name}
                  </Typography>
                  <Button
                    size="small"
                    onClick={() => handleRemoveFile(index)}
                    sx={{ color: 'error.main', minWidth: 'auto' }}
                  >
                    ×
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
          <Button 
            variant="contained" 
            onClick={() => onSave(formData)}
          >
            Änderungen speichern
          </Button>
          <Button 
            variant="outlined" 
            onClick={onClose}
          >
            Abbrechen
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EditTicket; 